"use client"

import { useAnalytics } from "@/lib/frontend/hooks/useAnalytics"
import { useWorkmapsContext, useDebouncedValue } from "@/lib/frontend/hooks"
import type { UseFormReturn } from "react-hook-form"
import { useUpdateEffect } from "@react-hookz/web"
import { useCallback } from "react"
import { useUserJobPreferences } from "./useUserJobPreferences"

type SearchFormState = Record<string, any> & {
  search: string[]
}

export type UseSearchProps = {
  form: UseFormReturn<SearchFormState>
  toggle?: (id: string | null) => void
}

export type UseSearch = ReturnType<typeof useSearch>

export const useSearch = ({ form, toggle }: UseSearchProps) => {
  const { state, dispatch } = useWorkmapsContext()
  const analytics = useAnalytics()
  const { updateJobSearchParams } = useUserJobPreferences()
  const debouncedSearch = useDebouncedValue(form.getValues().search, 500)

  useUpdateEffect(() => {
    if (debouncedSearch.length) {
      analytics.track("Search Entered", { search: debouncedSearch })
    }
  }, [debouncedSearch])

  const onInputChange = useCallback((event: any) => form.setValue("search", [event.currentTarget.value]), [form])

  const onSearchClear = useCallback(() => {
    const previousValue = form.getValues().search.join(", ")
    const value: string[] = []
    form.setValue("search", value)
    dispatch({ search: value })
    analytics.track("Search Cleared", {
      previousValue,
    })
  }, [form, dispatch, analytics])

  const submitData = useCallback(
    async (data: SearchFormState) => {
      const previousSearch = state.search

      dispatch({
        search: data.search,
        // We must unset the categories when searching as the user maybe searching from a slug page with categories and
        // those introduce a hard filter that the autocomplete largely ignores.
        categories: [],
        occupations: [],
        storeId: undefined,
        job: undefined,
        onNextStoresUpdate: (updatedState) => {
          analytics.track("Search Submitted", {
            search: data.search,
            previousSearch,
            stores: updatedState.stores.length,
            pristine: !previousSearch?.length ?? true,
            // If the user has added or removed things from the string
            edited:
              !!previousSearch?.length &&
              (data.search.join(", ").includes(previousSearch.join(", ")) ||
                previousSearch.join(", ").includes(data.search.join(", "))),
          })
        },
      })

      if (data.search.length) {
        updateJobSearchParams.mutate({
          search: data.search,
        })
      }

      toggle?.(null)
    },
    [analytics, state.search, dispatch, toggle, updateJobSearchParams]
  )

  const onSubmit = form.handleSubmit((data, e) => {
    if (typeof e?.target.checkValidity === "function" && !e.target.checkValidity()) {
      e.target.reportValidity()
      return
    }

    return submitData(data)
  })

  const submitValue = useCallback(
    async (search: string[]) => {
      form.setValue("search", search)
      await submitData({ search })
    },
    [form, submitData]
  )

  return {
    form,
    onInputChange,
    onSearchClear,
    onSubmit,
    submitValue,
  }
}
