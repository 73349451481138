"use client"

import { Button } from "@/components/ui/button"
import { Flex } from "@/components/ui/flex"
import { Text } from "@/components/ui/text"
import { LabeledCheckbox } from "@/components/ui/checkbox"
import { Collapsible, CollapsibleContent, CollapsibleTrigger } from "@/components/ui/collapsible"
import { useWorkmapsContext } from "@/lib/frontend/hooks"
import { cn } from "@/lib/frontend/shadcn"
import { useAnalytics } from "@/lib/frontend/hooks/useAnalytics"
import React, { ReactNode } from "react"
import { IconChevronDown, IconCornerLeftUp } from "@tabler/icons-react"
import type { MapState, SidebarFilterType } from "@/types"
import { RadioGroup, RadioGroupItem } from "@/components/ui/radio-group"
import { Label } from "@/components/ui/label"
import { jobCategories } from "@/lib/shared/categories"
import { useStoreFeedLayer } from "@/lib/frontend/hooks/statsig"
import { NoMatchingJobs } from "../NoMatchingJobs"
import { removeStringsFromText } from "@/lib/frontend/util"
import { usePathname, useRouter } from "next/navigation"
import { useSlug } from "@/lib/frontend/hooks/useSlug"
import { AlertWidget } from "../AlertWidgetCapture"

const minPayList = [15, 20, 25, 30, 40]

type FilterSectionProps = {
  section: string
  children: ReactNode
  onClear?: () => void
  className?: string
  showClearButton?: boolean
}

type FilterSectionCheckboxProps = {
  state: MapState
  section: SidebarFilterType
  onClear?: () => void
  handleJobCategoryFilter: (categoryValue: string, isChecked: boolean) => void
}

type SlugFiltersProps = {
  className?: string
  enabledFilters?: SidebarFilterType[]
  relatedSlugs?: Array<{ title: string; slug: string; icon: string | null }>
  headerTitle: string | undefined
  filterVersion?: string
  hideFilters?: boolean
  onClose?: () => void
}

const FilterSection = ({ section, children, onClear, className, showClearButton = false }: FilterSectionProps) => {
  return (
    <Collapsible defaultOpen className="group/collapsible">
      <Flex justify="between" align="center" gap="sm">
        <CollapsibleTrigger asChild>
          <Button variant="transparent" className="px-0 gap-2 min-w-0">
            <IconChevronDown
              className="transition-transform duration-200 group-data-[state=open]/collapsible:rotate-180 text-zinc-500 flex-shrink-0"
              size={16}
            />
            <Text weight="semibold" size="sm" className="truncate">
              {/* remove slug name from the sections */}
              {removeStringsFromText(section, ["Nurse", "Cook", "Warehouse", "Sales", "Retail", "Merchandiser"])}
            </Text>
          </Button>
        </CollapsibleTrigger>
        {showClearButton && (
          <Button variant="transparent" size="sm" className={cn("p-0  text-zinc-500")} onClick={onClear}>
            Clear
          </Button>
        )}
      </Flex>
      <CollapsibleContent className={cn("flex flex-col", className)}>
        <hr className="-mx-2 py-1" />
        {children}
      </CollapsibleContent>
    </Collapsible>
  )
}

const FilterSectionCheckbox = ({ state, section, onClear, handleJobCategoryFilter }: FilterSectionCheckboxProps) => {
  // filter jobs categories by section
  const filteredJobCategoriesBySection = jobCategories.filter((jobCategory) => jobCategory.section === section)

  // check to see if selected categories belong to the current section
  const selectedCategoriesInSection = filteredJobCategoriesBySection.filter((jobCategory) =>
    state.jobCategories.includes(jobCategory.value)
  )

  return (
    <FilterSection
      showClearButton={selectedCategoriesInSection.length > 0}
      className={cn("gap-1.5")}
      section={section}
      onClear={onClear}
    >
      {filteredJobCategoriesBySection.map((jobCategory) => {
        return (
          <LabeledCheckbox
            key={jobCategory.value}
            label={jobCategory.label}
            value={jobCategory.value}
            checked={(state.jobCategories ?? []).includes(jobCategory.value)}
            onCheckedChange={(isChecked: boolean) => handleJobCategoryFilter(jobCategory.value, isChecked)}
            size="sm"
            color="dark"
            className="h-5"
          />
        )
      })}
    </FilterSection>
  )
}

export const SlugFilters = ({
  className,
  enabledFilters,
  relatedSlugs,
  headerTitle,
  filterVersion,
  hideFilters,
  onClose,
}: SlugFiltersProps) => {
  const { state, dispatch } = useWorkmapsContext()
  const analytics = useAnalytics()

  // used to navigate to previous slug page for the back button
  // hacky way to do it since slug doesn't continue to update
  const router = useRouter()
  const slug = useSlug()
  const slugName = "/" + (!!slug && slug?.slug !== "index" ? slug.slug : "")
  const pathname = usePathname()

  const handleBack = () => {
    if (pathname === slugName) {
      router.push("/")
    } else {
      router.back()
    }
    analytics.track("Filters Back Button Pressed", { slug: pathname, filterVersion: filterVersion })
  }

  // get rid of the jobs-near-me for the header title
  // and update all + find jobs near you to filters
  const isTitleValid = headerTitle && headerTitle !== "Find Jobs Near You" && headerTitle !== "All "

  const { defaultSidebarFiltersV2 } = useStoreFeedLayer()
  const filters = enabledFilters?.length ? enabledFilters : defaultSidebarFiltersV2()

  // const PayChart = dynamic(() => import("./PayChart"), {
  //   ssr: false,
  //   loading: () => <p>Loading...</p>,
  // })

  // const handlePayRangeFilter = (range: [number, number]) => {
  //   const [min, max] = range
  //   dispatch({
  //     payMin: min,
  //     payMax: max,
  //   })
  //   analytics.track("Pay Range Filter Applied", {
  //     minPay: min,
  //     maxPay: max,
  //   })
  // }

  // round highest paying job to next whole number
  // const highestPayingJob = Math.ceil(getHighestPay(jobs))

  const handleJobCategoryFilter = (currentValue: string, isChecked: boolean) => {
    const checkedNurseCategoryFilters = isChecked
      ? [currentValue, ...(state.jobCategories ?? [])] // add currentValues to jobCategories
      : (state.jobCategories ?? []).filter((jobCategory) => jobCategory !== currentValue) // remove currentValue from jobCategories

    dispatch({ jobCategories: checkedNurseCategoryFilters })
    analytics.track("Filter Interaction", {
      type: "jobCategory",
      positive: isChecked,
      filterVersion,
      currentValue,
    })
  }

  const handleResetAll = () => {
    dispatch({ payMin: null, jobCategories: [] })
    analytics.track("Filter Interaction", {
      type: "resetAll",
      positive: false,
      filterVersion,
    })
  }

  const handleMinPayFilter = (currentValue: string) => {
    dispatch({
      payMin: currentValue ? Number(currentValue) : null,
    })
    analytics.track("Filter Interaction", {
      type: "minPay",
      positive: true,
      filterVersion,
      currentValue,
    })
  }

  const handleClearSection = (currentValue: string) => {
    if (currentValue === "Minimum Pay") {
      dispatch({ payMin: null })
      analytics.track("Filter Interaction", {
        type: "clearFilterSection",
        positive: false,
        filterVersion,
        currentValue,
      })
    } else {
      // get job categories from specific section
      const sectionCategories = jobCategories
        .filter((jobCategory) => jobCategory.section === currentValue)
        .map((jobCategory) => jobCategory.value)

      // remove job categories from specific section
      const jobCategoriesFromSection = state.jobCategories.filter(
        (categoryValue) => !sectionCategories.includes(categoryValue)
      )

      dispatch({
        jobCategories: jobCategoriesFromSection,
      })

      if (jobCategories) {
        analytics.track("Filter Interaction", {
          type: "clearFilterSection",
          positive: false,
          filterVersion,
          currentValue,
        })
      }
    }
  }

  const handleCloseButtonClick = () => {
    if (onClose) {
      onClose()
    }
    analytics.track("Mobile Filters Close Button Clicked", { filterVersion: filterVersion })
  }

  return (
    <Flex direction="col" className={cn("px-2 bg-[#f8f9fa] border-r", hideFilters && "hidden", className)}>
      <Flex direction="column" className={cn("sticky top-0 gap-1.5 bg-[#f8f9fa] -mx-2 px-2 pt-1.5 z-10")}>
        <Flex justify="between" align="center">
          <Flex className={cn("gap-1.5 min-h-7")} align="center">
            {isTitleValid && (
              <Button
                variant="outline"
                className={cn("border-0 bg-transparent rounded-md p-1 h-7 w-7 place-self-start max-md:hidden")}
                onClick={handleBack}
              >
                <IconCornerLeftUp size={16} className="text-zinc-500" />
              </Button>
            )}

            <Text weight="semibold" className="capitalize">
              {isTitleValid ? headerTitle : "Filter"}
            </Text>
          </Flex>

          <Flex gap="sm">
            {(state.jobCategories.length > 0 || state.payMin) && (
              <Button
                variant="transparent"
                className={cn("p-0 h-6 text-zinc-500 hover:underline")}
                onClick={handleResetAll}
              >
                Reset All
              </Button>
            )}
            <Button variant="transparent" className={cn("p-0 h-6 md:hidden")} onClick={handleCloseButtonClick}>
              Done
            </Button>
          </Flex>
        </Flex>
        <hr className="-mx-2" />

        <AlertWidget className={cn("mt-1 max-md:hidden")} />
      </Flex>

      <Flex direction="column" gap="sm">
        {filters?.map((filter) => {
          switch (filter) {
            // case "Generic Industry Links":
            //   return (
            //     <Flex direction="col" className="gap-1.5 pt-2.5">
            //       <ButtonLink href={""} variant="outline" className="justify-between bg-transparent group px-3">
            //         Link 1 <IconArrowUpRight size={16} className="text-zinc-500 group-hover:text-zinc-900" />
            //       </ButtonLink>
            //       <ButtonLink href={""} variant="outline" className="justify-between bg-transparent group px-3">
            //         Link 2 <IconArrowUpRight size={16} className="text-zinc-500 group-hover:text-zinc-900" />
            //       </ButtonLink>
            //     </Flex>
            //   )
            case "Industry":
              return (
                <FilterSection key={filter} section={filter}>
                  <NoMatchingJobs
                    showIcon={false}
                    className="gap-0 px-0"
                    slugClassName="border-none bg-transparent px-2 rounded-md h-9"
                    excludedStrings={["Jobs Near Me"]}
                  />
                </FilterSection>
              )
            case "Nurse Occupation":
              return (
                <FilterSection key={filter} section={filter}>
                  <NoMatchingJobs
                    showIcon={false}
                    slugs={relatedSlugs} // using related slugs to populate this section
                    className="gap-0"
                    slugClassName="border-none bg-transparent px-2 -mx-2 rounded-md"
                    excludedStrings={["Jobs Near Me"]}
                  />
                </FilterSection>
              )
            case "Nurse Experience Level":
            case "Nurse Specialty":
            case "Nurse Work Setting":
            case "Nurse Degree Requirement":
            case "Nurse Shift Schedule":
            case "Shift Preference":
            case "Benefits Offered":
            case "Age Requirement":
            case "Additional Requirement":

            case "Cook Position Type":
            case "Cook Work Setting":
            case "Cook Kitchen Shift":

            case "Warehouse Position Type":
            case "Warehouse Department":
            case "Warehouse Certification Requirement":

            case "Sales Industry":
            case "Sales Type":
            case "Sales Work Environment":
            case "Sales Travel Requirement":

            case "Retail Role":
            case "Retail Store Type":
            case "Retail Bonus":

            case "Merchandiser Role":
            case "Merchandiser Work Environment":

            case "Physical Requirement":
              return (
                <FilterSectionCheckbox
                  key={filter}
                  section={filter}
                  onClear={() => handleClearSection(filter)}
                  handleJobCategoryFilter={handleJobCategoryFilter}
                  state={state}
                />
              )
            case "Minimum Pay":
              return (
                <FilterSection
                  key={filter}
                  section="Minimum Pay"
                  showClearButton={!!state.payMin}
                  onClear={() => handleClearSection("Minimum Pay")}
                  className="gap-1.5"
                >
                  {/* can't set null for value so empty string helps clear the radio selection */}
                  <RadioGroup value={state.payMin?.toString() ?? ""} onValueChange={handleMinPayFilter}>
                    {minPayList.map((value) => (
                      <div key={value} className={cn("flex items-center space-x-2")}>
                        <RadioGroupItem color={"dark"} value={value.toString()} id={value.toString()} />
                        <Label htmlFor={value.toString()}>${value}+ per hour</Label>
                      </div>
                    ))}
                  </RadioGroup>
                </FilterSection>
              )
            default:
              return null
          }
        })}
      </Flex>
    </Flex>
  )
}
