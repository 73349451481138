"use client"

import { cn } from "@/lib/frontend/shadcn"
import { EmployerLogo } from "@/components/EmployerLogo"
import type { EnrichedStore } from "@/types"
import { formatStoreMarkerLabel } from "@/lib/frontend/util"
import { AdvancedMarker, AdvancedMarkerProps } from "@vis.gl/react-google-maps"
import styles from "./GoogleMapsMap.module.css"
import React, { forwardRef } from "react"
import { Text } from "@/components/ui/text"
import { uuidToNumberLossy } from "@/lib/util"
import { debugFeedColorsClassnames } from "@/lib/shared/feeds"
import { Badge } from "@/components/ui/badge"

type MinimalStore = Partial<EnrichedStore> & Pick<EnrichedStore, "lat" | "lng" | "exact_location" | "employer" | "id">

type MapMarkerProps = {
  store: EnrichedStore | MinimalStore
  mapPinVariation: string
  className?: string
}

type DefaultMapPin = {
  store: EnrichedStore | MinimalStore
  storeIsSelected: boolean
  mapPinModal: boolean
}

const Logo = ({
  store,
  className,
}: {
  store: EnrichedStore | MinimalStore
  className?: string
  mapPinModal?: boolean
  storeIsSelected?: boolean
}) => (
  <EmployerLogo
    employerTitle={store.employer.title}
    employerLogoUrl={store.employer.logo_url}
    className={cn(className)}
  />
)

const Pay = ({
  store,
  includeEstimatedLabel = true,
  className,
}: {
  store: EnrichedStore | MinimalStore
  includeEstimatedLabel?: boolean
  className?: string
}) => {
  if (!store.highestPay) return null

  return (
    <span className={cn("pay", className)}>
      {formatStoreMarkerLabel({
        pay: store.highestPay,
        includeDecimal: false,
        includeEstimatedLabel: includeEstimatedLabel,
      })}
    </span>
  )
}

export const DefaultMapPin: React.FC<DefaultMapPin> = ({ store, storeIsSelected, mapPinModal }) => {
  return (
    <>
      <Logo
        store={store}
        mapPinModal={mapPinModal}
        storeIsSelected={storeIsSelected}
        className={cn(
          "logo",
          !mapPinModal && [styles.logo, "h-20 w-20 text-3xl font-medium border-2 border-black"],
          mapPinModal &&
            (storeIsSelected
              ? "w-12 h-12 border-none text-xl"
              : "h-6 w-6 hover:w-8 hover:h-8 font-medium border-none text-xs hover:text-base")
        )}
      />
      {!mapPinModal && !!store.highestPay && <Pay store={store} className={styles.pay} includeEstimatedLabel={false} />}
    </>
  )
}

export const MapPinVariations: React.FC<MapMarkerProps> = ({ mapPinVariation, store, className }) => {
  const pinVariation = mapPinVariation

  return (
    <>
      {pinVariation === "logo" && <Logo store={store} className={className} />}

      {pinVariation === "logo_pay" && (
        <>
          <Logo store={store} className={className} />
          {!!store.highestPay && <Pay store={store} />}
        </>
      )}

      {pinVariation === "pay" &&
        (store.highestPay ? <Pay store={store} /> : <Logo store={store} className={className} />)}
    </>
  )
}

type StoreMarkerProps = Omit<AdvancedMarkerProps, "clickable" | "collisionBehavior" | "position"> & {
  store: EnrichedStore | MinimalStore
  pinVariation?: "default" | "logo" | "logo_pay" | "pay"
  selected?: boolean
  debugFeedColors?: boolean
  highlight?: string
  perkHighlight?: string
  onClick?: () => void

  // Experiments
  mapPinModal?: boolean
  pinHighlights?: boolean
  pinPerkHighlights?: boolean
}

const Highlight = ({ highlight, selected }: { highlight?: string; selected?: boolean }) => (
  <Text
    size="sm"
    weight="semibold"
    className={cn(
      "highlight",
      "absolute",
      "bg-white rounded-lg py-1.5 px-3 max-w-max w-[400px]",
      selected ? " bottom-12 border-4 border-primary" : "bottom-10"
    )}
    style={{ zIndex: 1000001 }}
  >
    {highlight}
    <Badge
      size="xs"
      circle
      className={cn(
        "absolute -top-4 left-1/2 -translate-x-1/2 z-[10001] bg-[#FCD34D] border-none shadow-md uppercase whitespace-nowrap"
      )}
    >
      Best Match
    </Badge>
  </Text>
)

const PerkHighlight = ({ perkHighlight, selected }: { perkHighlight: string; selected?: boolean }) => (
  <div className={cn("perkHighlight", "absolute", selected ? "bottom-14" : "bottom-12")}>
    <Text
      size="sm"
      weight="semibold"
      className={cn(
        "perkHighlightText",
        "whitespace-nowrap",
        "bg-[#0F172A] text-white rounded-full py-1.5 px-3 animate-wobble",
        selected && "text-xl"
      )}
      style={{ zIndex: 1000001 }}
    >
      {perkHighlight}
    </Text>

    {/* customer pointer */}
    <svg width="48" height="12" viewBox="0 0 48 12" className="absolute left-1/2 -bottom-2 -translate-x-1/2">
      <path d="M0,0 C16,0 16,4 24,12 C32,4 32,0 48,0" fill="#0F172A" />
    </svg>
  </div>
)

export const StoreMarker = forwardRef<google.maps.marker.AdvancedMarkerElement, StoreMarkerProps>(
  (
    {
      store,
      pinVariation = "default",
      selected,
      mapPinModal,
      pinHighlights,
      pinPerkHighlights,
      debugFeedColors,
      highlight,
      perkHighlight,
      onClick,
      zIndex,
    },
    ref
  ) => {
    return (
      <AdvancedMarker
        clickable
        zIndex={zIndex}
        position={{
          lat: store.exact_location ? store.lat : store.lat + (uuidToNumberLossy(store.id) * 2 - 1) * 0.003,
          lng: store.exact_location
            ? store.lng
            : store.lng + (uuidToNumberLossy(Array.from(store.id).reverse().join("")) * 2 - 1) * 0.003,
        }}
        collisionBehavior="REQUIRED"
        ref={ref}
        className={cn(
          "font-sans",
          "[&:has(.logo:hover)_.highlight]:bottom-12",
          "[&:has(.logo:hover)_.perkHighlightText]:text-xl",
          "[&:has(.logo:hover)_.perkHighlight]:bottom-14",
          (pinPerkHighlights || pinHighlights) && "flex items-center justify-center"
        )}
        onClick={onClick}
      >
        {perkHighlight && pinPerkHighlights && <PerkHighlight perkHighlight={perkHighlight} selected={selected} />}

        {highlight && pinHighlights && <Highlight highlight={highlight} selected={selected} />}
        <div
          className={cn(
            (pinVariation !== "default" || pinPerkHighlights || pinHighlights) && [
              "border border-muted-foreground",
              "flex flex-row items-center justify-center gap-1",
              "bg-white p-1 rounded-full color-[#404251] font-medium text-sm shadow-md text-nowrap notranslate",

              // When selected, green background...
              "[&.selected]:bg-primary",

              // Logo styles
              "[&>.logo]:rounded-full [&>.logo]:border-[1px] [&>.logo]:border-solid [&>.logo]:border-muted-foreground",
              "[&>.logo]:w-6 [&>.logo]:h-6 sm:[&>.logo]:w-7 sm:[&>.logo]:h-7",
              "[&:not(.selected)_.logo:hover]:w-8 [&:not(.selected)_.logo:hover]:h-8",
              "[&:not(.selected)_.logo:hover]:sm:w-9 [&:not(.selected)_.logo:hover]:sm:h-9",
              "[&.selected>.logo]:w-8 [&.selected>.logo]:h-8 sm:[&.selected>.logo]:w-9 sm:[&.selected>.logo]:h-9",

              // Pay text styles
              "[&.selected>.pay]:text-base",
              "[&:not(.selected)>.pay]:hover:text-base [&>.pay]:px-1",
              "[&:not(:has(>.pay)):not(:has(>.logo))>.pay]:px-0.5",

              // When logo + pay are present sizing
              "[&:has(>.pay):has(>.logo):not(.selected)_.logo:hover]:w-7",
              "[&:has(>.pay):has(>.logo):not(.selected)_.logo:hover]:h-7",
              "[&:has(>.pay):has(>.logo):not(.selected)_.logo:hover]:sm:w-8",
              "[&:has(>.pay):has(>.logo):not(.selected)_.logo:hover]:sm:h-8",
              "[&:has(>.pay):has(>.logo):not(.selected):has(>.pay:hover)>.logo]:w-7",
              "[&:has(>.pay):has(>.logo):not(.selected):has(>.pay:hover)>.logo]:h-7",
              "[&:has(>.pay):has(>.logo):not(.selected):has(>.pay:hover)>.logo]:sm:w-8",
              "[&:has(>.pay):has(>.logo):not(.selected):has(>.pay:hover)>.logo]:sm:h-8",
              "[&:has(>.pay):has(>.logo).selected>.logo]:w-7",
              "[&:has(>.pay):has(>.logo).selected>.logo]:h-7",
              "[&:has(>.pay):has(>.logo).selected>.logo]:sm:w-8",
              "[&:has(>.pay):has(>.logo).selected>.logo]:sm:h-8",
              "[&:has(>.pay):has(>.logo)>.pay]:pr-1",
              "[&:has(>.pay):has(>.logo)>.pay]:px-0",
            ],

            selected && [styles["selected-pin"], "selected"],
            styles["logo-pin"],
            debugFeedColors && debugFeedColorsClassnames({ logo: true, source: store.source })
          )}
        >
          {pinVariation === "default" && (!pinPerkHighlights || !pinHighlights) ? (
            <DefaultMapPin store={store} storeIsSelected={!!selected} mapPinModal={!!mapPinModal} />
          ) : (
            <MapPinVariations store={store} mapPinVariation={pinVariation} className={cn("logo")} />
          )}
        </div>
      </AdvancedMarker>
    )
  }
)

StoreMarker.displayName = "StoreMarker"
