import { useSearchSuggestions } from "@/lib/frontend/hooks/useSearchSuggestions"
import type { UseSearch } from "@/lib/frontend/hooks/useSearch"
import { Autocomplete, Option } from "@/components/ui/autocomplete"
import { useCallback } from "react"
import { useAnalytics } from "@/lib/frontend/hooks/useAnalytics"

interface Props {
  search: UseSearch
  className?: string
  label?: React.ReactNode
  defaultValue?: string
  placeholder?: string
  emptyMessage?: React.ReactNode
  onValueChange?: (value: Option) => void
  onInputChange?: (value: string) => void
  onNoResultsStateChange?: (noResults: boolean) => void
}

export const AutocompleteSearchInput: React.FC<Props> = ({
  search,
  className,
  label,
  defaultValue,
  placeholder = "search keywords",
  emptyMessage,
  onValueChange,
  onInputChange,
  onNoResultsStateChange,
}) => {
  const suggestions = useSearchSuggestions()
  const analytics = useAnalytics()

  const handleInputChange = useCallback(
    (value: string) => {
      const updatedValue = value.split(",").at(-1)?.trim()

      if (updatedValue) {
        suggestions.update(updatedValue)
      }

      onInputChange?.(value)
    },
    [suggestions, onInputChange]
  )

  const handleValueChange = useCallback(
    async (value: Option) => {
      await search.submitValue(value.value.split(","))
      analytics.track("Autocomplete Search Suggestion Selected", {
        value: value.value,
        label: typeof value.autocompleteLabel === "string" ? value.autocompleteLabel : value.label,
      })
      suggestions.update("")
      onValueChange?.(value)
    },
    [search, suggestions, analytics, onValueChange]
  )

  const handleClear = useCallback(() => {
    search.onSearchClear()
    suggestions.update("")
  }, [search, suggestions])

  // For v0, we are going to support any ol submission, even if it's invalid
  const handleSubmissionOfInvalidValue = useCallback(
    async (value: string) => {
      await search.submitValue(value.split(","))
      analytics.track("Autocomplete Search Submitted Without Suggestion", {
        value,
      })
    },
    [search, analytics]
  )

  return (
    <Autocomplete
      placeholder={placeholder}
      emptyMessage={emptyMessage}
      isLoading={suggestions.query.isLoading}
      inputClassName={className}
      leftSection={label ?? ""}
      defaultValue={defaultValue}
      onInputChange={handleInputChange}
      onValueChange={handleValueChange}
      onNoResultsStateChange={onNoResultsStateChange}
      onClear={handleClear}
      onSubmissionOfInvalidValue={handleSubmissionOfInvalidValue}
      options={
        suggestions.results?.map((res) => ({
          value: res.tag,
          label: res.tag,
        })) ?? []
      }
      required
    />
  )
}
