import { useCallback, useMemo, useState } from "react"
import { useAnalytics } from "@/lib/frontend/hooks/useAnalytics"
import { useWorkmapsContext } from "@/lib/frontend/hooks"
import { useToast } from "@/lib/frontend/hooks/use-toast"
import { useStoreFeedLayer } from "@/lib/frontend/hooks/statsig"

type CaptureProps = {
  uiPlacement?: string
  method?: "email" | "sms"
  toast?: {
    title: string
    description: string
  }
}

const toastTitle = "Success! Your search has been saved. 🎉"
const toastDescription = `We've sent you a message to confirm your saved alert. Rates may apply.`

export const useAlertWidget = (captureProps?: CaptureProps) => {
  const [isOpen, setIsOpen] = useState(false)
  const analytics = useAnalytics()
  const { state } = useWorkmapsContext()
  const { toast } = useToast()
  const { alertWidget } = useStoreFeedLayer()

  const handleButtonClick = useCallback(() => {
    analytics.track("shown_email_capture", {
      uiPlacement: "alertWidget",
      method: captureProps?.method ?? "email",
    })
    setIsOpen(true)
  }, [analytics, captureProps?.method])

  const handleCapture = useCallback(
    (
      params: { consent_to_marketing: boolean },
      onCapture?: (params: { consent_to_marketing: boolean }) => void,
      uiPlacement: string = "alertWidget"
    ) => {
      const trackingParams = {
        ...params,
        payMin: state.payMin,
        jobCategories: state.jobCategories,
        uiPlacement: captureProps?.uiPlacement ?? uiPlacement,
      }
      analytics.track("shown_email_capture_provided_email", trackingParams)
      onCapture?.(trackingParams)
      toast({
        title: captureProps?.toast?.title ?? toastTitle,
        description: captureProps?.toast?.description ?? toastDescription,
      })
      setIsOpen(false)
    },
    [analytics, state, toast, captureProps?.toast?.title, captureProps?.toast?.description, captureProps?.uiPlacement]
  )

  const handleClose = useCallback(() => {
    analytics.track("Alert Close Button Clicked")
    setIsOpen(false)
  }, [analytics])

  // show widget on filter selection
  const filterSelected = state.payMin || state.jobCategories.length > 0

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const alertWidgetExperiment = useMemo(() => alertWidget(), [])

  return {
    isOpen,
    setIsOpen,
    handleButtonClick,
    handleCapture,
    handleClose,
    filterSelected,
    alertWidgetExperiment,
  }
}
