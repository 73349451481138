"use client"

import { cn } from "@/lib/frontend/shadcn"
import { Card, CardContent } from "@/components/ui/card"
import { Flex } from "@/components/ui/flex"
import { Text } from "@/components/ui/text"
import { Badge } from "@/components/ui/badge"
import { Tooltip, TooltipContent, TooltipTrigger } from "@/components/ui/tooltip"
import { decimalToNumber, payRangeTextContent } from "@/lib/util"
import { useDebugFeedColors, useWorkmapsContext } from "@/lib/frontend/hooks"
import type { ApiStoreSearchResult, ApiListJob } from "@/types"
import { AppcastApplyLink } from "@/components/AppcastApplyLink"
import React from "react"
import useApply from "@/lib/frontend/hooks/useApply"
import { hasAnyDisplayableJobCategories } from "@/lib/shared/categories"
import { debugFeedColorsClassnames } from "@/lib/shared/feeds"

interface Props {
  job: ApiListJob
  store: ApiStoreSearchResult
  uiPlacement: string
  lineClamp?: boolean
  selected?: boolean
  onJobClick?: ({ jobId, storeId }: Record<"jobId" | "storeId", string>) => void
}

export const JobCard: React.FC<Props> = ({ job, store, selected, uiPlacement, onJobClick }) => {
  const debugFeedColors = useDebugFeedColors()

  // Instead of showing an empty job page with a preview of just the job description, clicking a job without any
  // scannable categories will take the user right to the employer's job page, as they are probably going to format it
  // better than us.
  const clickTakesUserDirectlyToEmployerJobPage =
    !hasAnyDisplayableJobCategories(job.job_categories) && job.source !== "workmaps"
  const Wrapper = clickTakesUserDirectlyToEmployerJobPage ? DirectLinkWrapper : "div"

  return (
    <Card
      role="button"
      id={`job-card-${job.id}`}
      key={job.id}
      className={cn(
        "flex",
        "items-center",
        "cursor-pointer",
        "text-left",
        "p-2.5",
        "rounded-[.625rem]",
        !selected && "hover:bg-[rgba(0.1,0.1,0.1,0.05)]",
        selected && "bg-[#E1FDB9]",
        debugFeedColors && debugFeedColorsClassnames({ source: job.source })
      )}
      data-workmaps-source={debugFeedColors ? job.source : undefined}
      title={debugFeedColors ? `Source: ${job.source}` : undefined}
      onClick={(e) => {
        e.stopPropagation()
        onJobClick?.({ jobId: job.id, storeId: store.id })
      }}
      asChild
    >
      <Wrapper job={job}>
        <CardContent className={cn("flex", "flex-row", "justify-between", "items-center", "gap-2", "w-full")}>
          <Flex direction="col" justify="center">
            <Flex gap="sm">
              {job.urgent && (
                <Badge radius="sm" size="xss" className={cn("bg-[#69AE59] text-white border-none")}>
                  Urgently Hiring
                </Badge>
              )}
              {/* {isRecentlyAdded(job.job_posted_at) && (
              <Badge radius="sm" size="xss" className="mb-1" color="success" outlined>
                Recently Added
              </Badge>
            )} */}
            </Flex>
            <Text size="sm" className="max-xss:text-xs" weight="medium">
              {job.title}
            </Text>
            <Flex>
              {job.pay_min && (
                <Text className={cn("max-xss:text-xs", "text-[#228620]")} size="sm" lineClamp={1}>
                  {job.pay_min || job.pay_max
                    ? payRangeTextContent(decimalToNumber(job.pay_min), decimalToNumber(job.pay_max))
                    : undefined}
                  {job.pay_estimated && (
                    <Tooltip delayDuration={0}>
                      <TooltipTrigger className={cn("text-inherit ml-1")}>(Est.)</TooltipTrigger>
                      <TooltipContent side="bottom">Estimated Pay</TooltipContent>
                    </Tooltip>
                  )}
                </Text>
              )}
            </Flex>
          </Flex>
          <Flex align="center" gap="xs" className="ml-2">
            <AppcastApplyLink
              job={job}
              uiPlacement={uiPlacement}
              onClickTrackingParams={{
                jobIsInDifferentBadgeTest: job.urgent,
              }}
            />
          </Flex>
        </CardContent>
      </Wrapper>
    </Card>
  )
}

const DirectLinkWrapper = React.forwardRef<
  HTMLAnchorElement,
  Omit<React.PropsWithChildren<{ job: ApiListJob }> & React.HTMLAttributes<HTMLAnchorElement>, "onClick">
>(({ job, children, ...props }, ref) => {
  const { dispatch } = useWorkmapsContext()
  const { requireCapture, url, handleConversion, handleClickRequiringEmail } = useApply(job, {
    trackingParams: { uiPlacement: "direct-link-job-card" },
  })

  if (!requireCapture) {
    return (
      <a
        ref={ref}
        href={url}
        target="_blank"
        onClick={(e) => {
          e.stopPropagation()
          handleConversion()
          dispatch({ storeId: job.store_id })
        }}
        {...props}
      >
        {children}
      </a>
    )
  }

  return (
    <a
      href={url}
      target="_blank"
      ref={ref}
      onClick={(e) => {
        e.preventDefault()
        e.stopPropagation()
        dispatch({ storeId: job.store_id })
        // We have to wait for the URL to update so that the modal does not flicker because of a state update.
        setTimeout(() => handleClickRequiringEmail(), 20)
      }}
      {...props}
    >
      {children}
    </a>
  )
})
DirectLinkWrapper.displayName = "DirectLinkWrapper"
