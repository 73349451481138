"use client"

import { Flex } from "./ui/flex"
import { Text } from "./ui/text"
import { Card, CardContent } from "./ui/card"
import {
  IconArrowRight,
  IconBuildingWarehouse,
  IconFirstAidKit,
  IconToolsKitchen,
  IconBuildingStore,
  IconBriefcase,
  TablerIconsProps,
  IconTruck,
  IconDentalBroken,
  IconSchool,
  IconPhone,
  IconBucket,
} from "@tabler/icons-react"
import { cn } from "@/lib/frontend/shadcn"
import Link from "next/link"
import { useCurrentUrl } from "@/lib/frontend/hooks"
import { useAnalytics } from "@/lib/frontend/hooks/useAnalytics"
import { removeStringsFromText } from "@/lib/frontend/util"

export type RelatedSlug = {
  title: string
  slug: string
}

type NoMatchingJobsProps = {
  text?: React.ReactNode
  className?: string
  uiPosition?: string
  slugs?: {
    title: string
    slug: string
    icon: string | null
  }[]
  slugClassName?: string
  onSlugClick?: (slug: RelatedSlug) => void
  showIcon?: boolean
  excludedStrings?: string[]
}
const iconMap: Record<string, (props: TablerIconsProps) => JSX.Element> = {
  "fast-food": IconToolsKitchen,
  nurse: IconFirstAidKit,
  "part-time": IconBriefcase,
  retail: IconBuildingStore,
  warehouse: IconBuildingWarehouse,
  driving: IconTruck,
  dental: IconDentalBroken,
  education: IconSchool,
  phone: IconPhone,
  cooking: IconToolsKitchen,
  cleaning: IconBucket,
}

const defaultIcon = "part-time"
const defaultSlugs = [
  { title: "Fast Food Jobs Near Me", icon: IconToolsKitchen, slug: "fast-food-jobs-near-me" },
  { title: "Nursing Jobs Near Me", icon: IconFirstAidKit, slug: "nurse-jobs-near-me" },
  { title: "Part-Time Jobs Near Me", icon: IconBriefcase, slug: "part-time-jobs-near-me" },
  { title: "Retail Jobs Near Me", icon: IconBuildingStore, slug: "retail-jobs-near-me" },
  { title: "Warehouse Jobs Near Me", icon: IconBuildingWarehouse, slug: "warehouse-jobs-near-me" },
]

export const NoMatchingJobs = ({
  text,
  slugs,
  className,
  slugClassName,
  onSlugClick,
  uiPosition,
  showIcon = true,
  excludedStrings,
}: NoMatchingJobsProps) => {
  const analytics = useAnalytics()
  const currentUrl = new URL(useCurrentUrl())
  let filteredSlugPages = defaultSlugs

  if (slugs?.length) {
    filteredSlugPages = slugs
      .filter((s) => !currentUrl.pathname.includes(s.slug))
      .map((slug) => ({
        ...slug,
        icon: iconMap[slug.icon ?? defaultIcon],
      }))
  }

  const queryParameters = new URLSearchParams()

  if (currentUrl.searchParams.has("lat")) {
    queryParameters.set("lat", currentUrl.searchParams.get("lat")!)
  }

  if (currentUrl.searchParams.has("lng")) {
    queryParameters.set("lng", currentUrl.searchParams.get("lng")!)
  }

  return (
    <Flex direction="col" className={cn("px-3 gap-2", className)}>
      {text && (
        <Text size="sm" className="mb-2">
          {text}
        </Text>
      )}

      {filteredSlugPages.map((slugPage) => {
        const pathname = `/${slugPage.slug}`
        return (
          <Card
            key={slugPage.slug}
            className={cn("px-4 py-2.5 cursor-pointer hover:bg-zinc-100 group", slugClassName)}
            onClick={() => {
              analytics.track("No Match - Slug Page Clicked", { title: slugPage.title, pathname, uiPosition })
              onSlugClick?.(slugPage)
            }}
            asChild
          >
            <Link href={`${pathname}${queryParameters.size > 0 ? "?" + queryParameters.toString() : ""}`}>
              <CardContent justify="between" direction="row">
                <Flex gap="sm">
                  {showIcon && <slugPage.icon size={20} />}
                  <Text weight="medium" size="sm">
                    {removeStringsFromText(slugPage.title, excludedStrings)}
                  </Text>
                </Flex>
                <IconArrowRight size={16} className="text-zinc-500 group-hover:text-zinc-900 flex-shrink-0" />
              </CardContent>
            </Link>
          </Card>
        )
      })}
    </Flex>
  )
}
