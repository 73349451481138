import { useForm } from "react-hook-form"
import useContactInformationCapture from "@/lib/frontend/hooks/useContactInformationCapture"
import { cn } from "@/lib/frontend/shadcn"
import { IconBellRinging, IconX } from "@tabler/icons-react"
import { Button } from "@/components/ui/button"
import { Flex } from "@/components/ui/flex"
import { Text } from "@/components/ui/text"
import { Dialog, DialogContent } from "@/components/ui/dialog"
import { LabeledInput } from "@/components/ui/input"
import { BellDot } from "lucide-react"
import { useAlertWidget } from "@/lib/frontend/hooks/useAlertWidget"
import { DialogDescription, DialogTitle } from "@/components/ui/dialog"
import { Card } from "@/components/ui/card"

type AlertWidgetFormData = {
  phone_number: string
  consent_to_marketing: boolean
}

type AlertWidgetFormProps = {
  cta?: React.ReactNode
  className?: string
  onCapture?: (params: { consent_to_marketing: boolean }) => void
  onOpen?: () => void
  onClose?: () => void
  buttonVariant?: "black" | "default"
  desktopView?: boolean
}

const backGroundGradient = (
  <div className={cn("absolute inset-0 -z-10 bg-white rounded-2xl")}>
    <img
      src="/images/saveSearchMap.png"
      alt="background image"
      className={cn("w-full h-full object-cover opacity-25")}
    />
    <div
      className={cn("absolute inset-0")}
      style={{
        backgroundImage: "linear-gradient(to bottom, rgba(255, 255, 255, 0), #FFFFFF)",
      }}
    />
  </div>
)

const alertWidgetContent = {
  title: <Text weight="bold">Save This Search</Text>,
  description: (
    <>
      <Text size="sm" className={cn("text-zinc-500")}>
        Never miss an opportunity!
        <br />
        Get instant alerts for new job postings matching your search—for free*. Start finding the job that fits your
        needs today!
      </Text>
      <Text className={cn("text-zinc-500")} size="xs">
        *rates may apply
      </Text>
    </>
  ),
}

export const AlertWidgetForm: React.FC<AlertWidgetFormProps> = ({
  cta,
  onCapture,
  onClose,
  desktopView = false,
  buttonVariant = "black",
}) => {
  const form = useForm<AlertWidgetFormData>({
    defaultValues: {
      phone_number: "",
      consent_to_marketing: true,
    },
  })
  const { capture } = useContactInformationCapture({
    onError: () => form.setError("root", { message: "An error occurred. Please try again." }),
  })

  const handleSubmit = form.handleSubmit(async (data, e) => {
    if (e?.currentTarget?.checkValidity?.()) {
      return false
    }

    try {
      await capture(data)
    } catch (_e) {
      form.setError("root", { message: "An error occurred. Please try again." })
      return false
    }

    onCapture?.({ consent_to_marketing: data.consent_to_marketing })
  })

  return (
    <form
      onSubmit={handleSubmit}
      id="alert-widget-capture-form"
      className={cn("flex", "flex-col", "gap-2.5", "relative", desktopView && "border p-2.5 rounded-2xl shadow-sm")}
    >
      <div className={cn(!desktopView && "hidden")}>
        {backGroundGradient}

        <Flex direction="col" className={cn("gap-2 mb-4")}>
          <Flex justify="between" align="center">
            <Text
              size="sm"
              className={cn(
                "flex h-10 w-10 rounded-full bg-[#FFFFDD] items-center justify-center shadow-sm animate-wobble"
              )}
            >
              🔔
            </Text>
            <Button size="icon" variant="transparent" onClick={onClose} type="button">
              <IconX size={20} />
            </Button>
          </Flex>
          <Text weight="bold">{alertWidgetContent.title}</Text>
          {alertWidgetContent.description}
        </Flex>
      </div>

      <LabeledInput
        type="tel"
        placeholder="Phone Number"
        data-testid="phone-number-input"
        autoComplete="tel"
        label="Your Phone Number"
        error={form.formState.errors.phone_number?.message ?? form.formState.errors.root?.message}
        {...form.register("phone_number", {
          required: true,
        })}
        className={cn("rounded-[8px]")}
      />
      <Button
        className={cn("rounded-lg")}
        variant={buttonVariant}
        type="submit"
        fullWidth
        disabled={!form.formState.isValid}
        loading={form.formState.isSubmitting}
      >
        {cta}
      </Button>
    </form>
  )
}

export const AlertWidget = ({ className }: { className?: string }) => {
  const { isOpen, handleButtonClick, handleCapture, handleClose, filterSelected, alertWidgetExperiment } =
    useAlertWidget({ method: "sms" })

  return (
    alertWidgetExperiment && (
      <Flex className={cn(className)}>
        {!isOpen ? (
          <Button
            variant="outline"
            rounded="lg"
            fullWidth
            className={cn(
              "gap-2 relative",
              // shine effect when filter is selected
              filterSelected &&
                "before:absolute before:inset-0 before:rounded-lg before:bg-[linear-gradient(45deg,transparent_25%,hsl(var(--primary)/0.5)_50%,transparent_75%,transparent_100%)] before:bg-[length:250%_250%,100%_100%] before:bg-[position:200%_0,0_0] before:bg-no-repeat before:animate-shine"
            )}
            onClick={handleButtonClick}
          >
            <BellDot size={16} /> Create Alert
          </Button>
        ) : (
          <AlertWidgetForm cta="Save Search" onCapture={handleCapture} onClose={handleClose} desktopView={true} />
        )}
      </Flex>
    )
  )
}

export const MobileAlertWidget = ({ buttonStyle, showLabel = true }: { buttonStyle?: string; showLabel?: boolean }) => {
  const { isOpen, setIsOpen, handleButtonClick, handleCapture, handleClose, alertWidgetExperiment } = useAlertWidget({
    method: "sms",
  })

  return (
    alertWidgetExperiment && (
      <div className={cn("md:hidden")}>
        <Button variant="outline" rounded="lg" className={cn("gap-2", buttonStyle)} onClick={handleButtonClick}>
          <BellDot className={cn("flex-shrink-0")} size={18} /> {showLabel && "Create Alert"}
        </Button>

        {isOpen && (
          <Dialog open={isOpen} onOpenChange={(opened) => setIsOpen(opened)}>
            <DialogContent
              onOpenAutoFocus={(e) => e.preventDefault()}
              onClick={(e) => e.stopPropagation()}
              className={cn("max-w-[27.5rem] max-sm:top-[33%] p-2.5 overflow-clip gap-2.5")}
            >
              {backGroundGradient}

              <Text
                size="sm"
                className={cn(
                  "flex h-10 w-10 rounded-full bg-[#FFFFDD] items-center justify-center shadow-sm animate-wobble"
                )}
              >
                🔔
              </Text>
              <DialogTitle>{alertWidgetContent.title}</DialogTitle>
              <DialogDescription className="gap-2 flex flex-col mb-4">
                {alertWidgetContent.description}
              </DialogDescription>
              <AlertWidgetForm cta="Save Search" onCapture={handleCapture} onClose={handleClose} />
            </DialogContent>
          </Dialog>
        )}
      </div>
    )
  )
}

export const EmbeddedAlertWidget = ({ isSearch = false }: { isSearch?: boolean }) => {
  const { handleCapture } = useAlertWidget({
    uiPlacement: isSearch ? "alertWidgetMobileSearch" : "alertWidgetJobsSidebar",
    method: "sms",
    toast: {
      title: "Success! You will be notified. 🎉",
      description: `We've sent you a message to confirm notifications. Rates may apply`,
    },
  })

  return (
    <Card
      className={cn(
        "mx-2.5 rounded-2xl overflow-clip gap-2.5 mb-2.5 relative",
        isSearch ? "bg-transparent" : "bg-white"
      )}
    >
      <Flex direction="col" className={cn("gap-2.5 mb-4")}>
        {isSearch && backGroundGradient}
        <Text
          size="sm"
          className={cn(
            "flex h-10 w-10 rounded-full items-center justify-center shadow-sm animate-wobble",
            isSearch ? "bg-[#FFFFDD]" : "bg-blue-50"
          )}
        >
          {isSearch ? "🔔" : "📬"}
        </Text>
        <Text weight="bold">{isSearch ? "No Results Found" : "Be The First To Get Notified"}</Text>
        <Text size="sm" className={cn("text-zinc-500")}>
          Enter your phone number to receive exclusive alerts the moment new job opportunities are posted in this area.
        </Text>
        <Text className={cn("text-zinc-500")} size="xs">
          Message and data rates may apply.
        </Text>
      </Flex>
      <AlertWidgetForm
        cta={
          <Flex className="gap-2">
            <IconBellRinging size={20} /> Notify Me
          </Flex>
        }
        buttonVariant="default"
        onCapture={handleCapture}
      />
    </Card>
  )
}
