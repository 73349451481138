// This component is mostly adapted from https://github.com/armandsalle/my-site/blob/074705e7bd998b22ed2459e001a38d950e0d4fba/src/react/autocomplete.tsx
import { Command as CommandPrimitive } from "cmdk"
import { useState, useRef, useCallback, type KeyboardEvent } from "react"
import { Check } from "lucide-react"

import { CommandGroup, CommandItem, CommandList, CommandInput } from "./command"
import { inputVariants } from "./input"
import { Button } from "./button"
import { Spinner } from "./spinner"
import { Flex } from "./flex"
import { cn } from "@/lib/frontend/shadcn"
import { IconX } from "@tabler/icons-react"

export type Option = {
  value: string
  label: string
  autocompleteLabel?: React.ReactNode
  submittedInputLabel?: string
}

export type AutocompleteProps = {
  options: Option[]
  emptyMessage?: React.ReactNode
  value?: Option
  isLoading?: boolean
  disabled?: boolean
  required?: boolean
  placeholder?: string
  leftSection?: React.ReactNode
  rightSection?: React.ReactNode
  className?: string
  inputClassName?: string
  inputName?: string
  id?: string
  defaultValue?: string
  shouldFilter?: boolean
  forceOpenOnFocus?: boolean

  onInputChange?: (value: string) => any
  onValueChange?: (value: Option) => any
  onClear?: () => any
  onSubmissionOfInvalidValue?: (value: string) => any
  onNoResultsStateChange?: (noResults: boolean) => void
}

export const Autocomplete: React.FC<AutocompleteProps> = ({
  options,
  placeholder,
  emptyMessage = "No results",
  value,
  disabled,
  required,
  rightSection,
  leftSection,
  className,
  inputClassName,
  isLoading = false,
  id,
  defaultValue,
  shouldFilter = false,
  forceOpenOnFocus,

  onInputChange,
  onValueChange,
  onClear,
  onSubmissionOfInvalidValue,
  onNoResultsStateChange,
}) => {
  const inputRef = useRef<HTMLInputElement>(null)
  const noResultRef = useRef(false)

  const [isOpen, setOpen] = useState(false)
  const [selected, setSelected] = useState(value)
  const [inputValue, setInputValue] = useState<string | undefined>(value?.label ?? defaultValue ?? "")

  const handleClear = useCallback(() => {
    setInputValue("")
    setSelected(undefined)
    onClear?.()
  }, [onClear])

  const handleBlur = useCallback(() => {
    setOpen(false)
  }, [])

  const close = useCallback(() => {
    // This is a hack to prevent the input from being focused after the user selects an option
    // We can call this hack: "The next tick"
    setTimeout(() => {
      inputRef?.current?.blur()
    }, 0)

    setTimeout(() => {
      setSelected(undefined)
    }, 10)
  }, [])

  const handleSelectOption = useCallback(
    (selectedOption: Option) => {
      setInputValue(selectedOption?.submittedInputLabel ?? selectedOption.label)
      setSelected(selectedOption)
      onValueChange?.(selectedOption)
      close()
    },
    [onValueChange, close]
  )

  const handleKeyDown = useCallback(
    (event: KeyboardEvent<HTMLDivElement>) => {
      const input = inputRef.current
      if (!input) {
        return
      }

      // Keep the options displayed when the user is typing
      if (!isOpen) {
        setOpen(true)
      }

      // This is not a default behaviour of the <input /> field
      if (event.key === "Enter" && input.value !== "") {
        const optionToSelect = options.find((option) => option.label === input.value)
        if (optionToSelect) {
          handleSelectOption(optionToSelect)
        } else if (onSubmissionOfInvalidValue && !options.length) {
          onSubmissionOfInvalidValue(input.value)
          close()
        }
      }

      if (event.key === "Escape") {
        input.blur()
      }
    },
    [isOpen, options, handleSelectOption, close, onSubmissionOfInvalidValue]
  )

  const handleInputChanged = useCallback(
    (value: string) => {
      setInputValue(value)
      onInputChange?.(value)
    },
    [onInputChange]
  )

  return (
    <CommandPrimitive onKeyDown={handleKeyDown} className={cn(className)} shouldFilter={shouldFilter}>
      <div>
        <CommandInput
          onInput={(e) => {
            // This listens for the click of the close button on an HTML
            // <input type="search">. We need to manually submit in that
            // case.
            // https://stackoverflow.com/a/69665034/471292
            if (!e.currentTarget.value) {
              onClear?.()
            }
          }}
          id={id}
          ref={inputRef}
          value={inputValue}
          onValueChange={handleInputChanged}
          onBlur={handleBlur}
          onFocus={(e) => {
            // Only show the dropdown if the user has inputted something net-new
            if (!defaultValue || e.target.value !== defaultValue) {
              setOpen(true)
            }

            // Because these inputs are often prefilled, the user selecting them is going to delete and this makes it
            // much easier.
            e.target.select()
          }}
          placeholder={placeholder}
          disabled={disabled}
          leftSection={leftSection}
          defaultValue={defaultValue}
          rightSection={
            <Flex className="min-w-5 min-h-5" direction="col" align="center" justify="center">
              {isLoading && <Spinner color="primary" />}
              {!isLoading && inputValue && (
                <Button
                  size="small-icon"
                  variant="ghost"
                  aria-label="Clear"
                  onClick={handleClear}
                  className={cn("hover:bg-transparent")}
                >
                  <IconX size={16} />
                </Button>
              )}
              {rightSection}
            </Flex>
          }
          containerClassName={inputVariants({ className: inputClassName, relativeFocus: true })}
          aria-busy={isLoading}
          required={required}
          data-hj-allow
        />
      </div>
      <div className="relative">
        <div
          className={cn(
            "animate-in fade-in-0 zoom-in-95 absolute top-0 z-50 w-full rounded-sm bg-white outline-none shadow-md mt-2",
            isOpen && (inputValue || forceOpenOnFocus) ? "block" : "hidden"
          )}
        >
          <CommandList className="rounded-lg ring-1 ring-slate-200">
            {options.length > 0 ? (
              <CommandGroup>
                {options.map((option) => {
                  const isSelected = selected?.value === option.value
                  return (
                    <CommandItem
                      key={option.value}
                      value={option.label}
                      onMouseDown={(event) => {
                        event.preventDefault()
                        event.stopPropagation()
                      }}
                      onSelect={() => handleSelectOption(option)}
                      className={cn(
                        "flex w-full items-center gap-2 text-gray-700 cursor-pointer",
                        !isSelected ? "px-3" : null
                      )}
                    >
                      {isSelected ? <Check className="w-4" /> : null}
                      {option.autocompleteLabel ?? option.label}
                    </CommandItem>
                  )
                })}
              </CommandGroup>
            ) : null}
            {!isLoading && inputValue ? (
              <CommandPrimitive.Empty
                ref={() => {
                  noResultRef.current = true
                  onNoResultsStateChange?.(true)
                  return () => {
                    noResultRef.current = false
                    onNoResultsStateChange?.(false)
                  }
                }}
                className="select-none rounded-sm px-2 py-3 text-center text-sm"
              >
                {emptyMessage}
              </CommandPrimitive.Empty>
            ) : null}
          </CommandList>
        </div>
      </div>
    </CommandPrimitive>
  )
}
