"use client"

import { useWorkmapsContext } from "@/lib/frontend/hooks"
import { useWorkmapsJobsCatalog } from "./WorkmapsJobsCatalogProvider"
import type { SlugPageResult } from "@/dal/getSlugPage"
import { useMemo, useCallback, useEffect } from "react"
import { debounce, useIsMedium } from "@/lib/frontend/util"
import type { MapState, EnrichedStore, EnrichedJob, GeoData } from "@/types"
import { GoogleMapsMap, GoogleMapsApiProvider } from "@/components/map/GoogleMapsMap"
import { cn } from "@/lib/frontend/shadcn"
import { UrlErrorDisplay } from "@/components/UrlErrorDisplay"
import { useAnalytics } from "@/lib/frontend/hooks/useAnalytics"
import { useMap } from "@/components/MapProvider"
import { getLogoUrl } from "@/lib/shared/gradientColors"
import type { Cluster } from "@googlemaps/markerclusterer"
import { WhatWhereHeader } from "@/components/WhatWhereHeader"
import { MobileWhatWhere } from "@/components/MobileWhatWhere"
import { MapListToggle } from "@/components/MapListToggle"
import { MobileStoreCardPreview } from "@/components/MobileStoreCardPreview"
import { JobsSidebar } from "@/components/jobs-sidebar/JobsSidebar"
import { SlugHeroDetailsProps } from "@/components/SlugHeroDetails"
import { useQuery } from "@tanstack/react-query"
import { useStoreFeedLayer } from "@/lib/frontend/hooks/statsig"
import { useMapLayer } from "@/lib/frontend/hooks/statsig"
import { StoresSidebar } from "@/components/stores-sidebar/StoresSidebar"
import { usePathname, useRouter } from "next/navigation"
import useJobPage from "./job-page-v3/useJobPage"
import { SlugFilters } from "./filters-v2/SlugFilters"
import { Filters } from "./Filters"

interface WorkmapsContainerProps {
  slug?: SlugPageResult
  userGeo: GeoData
  sidebarMode?: "stores" | "jobs"
  defaultMobileView?: "map" | "list"
  sidebarTitle?: string
}

export const WorkmapsContainer: React.FC<WorkmapsContainerProps> = ({
  slug,
  userGeo,
  sidebarMode = "jobs",
  defaultMobileView = "list",
  sidebarTitle,
}) => {
  const { state, dispatch } = useWorkmapsContext()
  const { jobs, selectedJob: _selectedJob, stores, selectedStore, isLoading: jobsLoading } = useWorkmapsJobsCatalog()
  const analytics = useAnalytics()
  const { jobPageModal, sidebarFilterVersion, slugPagesWithFiltersDisabled } = useStoreFeedLayer()
  const { mapPinVariations } = useMapLayer()
  const { mapVisible, setMapVisible } = useMap()
  const isMedium = useIsMedium()
  const router = useRouter()
  const currentPathname = usePathname()
  const { closingAnimation } = useJobPage()

  // We want to capture the initial state, so tracking updates would be bad
  /* eslint-disable-next-line react-hooks/exhaustive-deps */
  const initialState = useMemo(() => state, [])
  const prefilledLocation =
    slug?.whereText ??
    ("city" in userGeo && (userGeo.city || userGeo.region) && !initialState.storeId
      ? (userGeo.city ? `${userGeo.city}, ` : "") + userGeo.region
      : undefined)

  // Mounting the map on mobile when it is not visible slows down scrolling through the list on low end Android phones.
  // Lazy loading it until they press on the map/list toggle makes the list snappy at the trade off of the map taking
  // a second to load when toggled.
  const mountMap = !isMedium || mapVisible

  useEffect(() => {
    if (defaultMobileView === "map") {
      setMapVisible(true)
    }
  }, [defaultMobileView, setMapVisible])

  useQuery({
    queryKey: ["user", "geo"],
    initialData: userGeo,
  })

  // #region Event handlers
  /* eslint-disable-next-line react-hooks/exhaustive-deps */
  const handleMapChange = useCallback(
    debounce(({ lat, lng, zoom }: MapState) => {
      dispatch({
        lat,
        lng,
        zoom,
        driftedFromDefaultState: true,
        callerLocation: "handleMapChange",
      })
    }, 200),
    []
  )

  const handleJobCardClicked = useCallback(
    (job: EnrichedJob, setStoreId = true) => {
      analytics.track("Job Clicked", { job })
      jobPageModal()
        ? router.push(`/job/${job.slug}`)
        : dispatch({ storeId: setStoreId ? job.store_id : undefined, job: job.id })
    },
    [analytics, dispatch, jobPageModal, router]
  )

  const handleMapPinClicked = useCallback(
    (store: EnrichedStore, trackingParams: Record<string, any> | undefined) => {
      analytics.track("Pin click", {
        storeId: store.id,
        pay_estimated: store.payEstimated,
        logo: getLogoUrl(store.employer.logo_url),
        mapPinVariations: mapPinVariations(),
        ...trackingParams,
      })

      // TODO Doing this with a JS event is dirty and we probably shouldn't do
      // it this way. We really need a UI reducer or some sort of event
      // listening system in React if we feel strongly about this.
      // When clicking on a pin, make the sidebar scroll to the store
      window.dispatchEvent(new CustomEvent("mapPinClicked", { detail: { storeId: store.id } }))
      dispatch({ storeId: store.id })
    },
    [analytics, dispatch, mapPinVariations]
  )

  const handleClusterClick = useCallback(
    (_event: google.maps.MapMouseEvent, cluster: Cluster) => {
      analytics.track("Cluster click", { count: cluster.count })
    },
    [analytics]
  )

  const handleJobClick = useCallback(
    ({ jobId, storeId }: Record<"jobId" | "storeId", string>) => {
      jobPageModal() ? router.push(`/job/${jobId}`) : dispatch({ job: jobId, storeId: storeId })
      analytics.track("Job Clicked", { jobId, storeId })
    },
    [analytics, dispatch, jobPageModal, router]
  )

  const handleJobPageClose = useCallback(() => {
    dispatch({ job: undefined, storeId: undefined, driftedFromDefaultState: true })
  }, [dispatch])

  const handleStoreCardClicked = useCallback(
    (store: EnrichedStore) => {
      jobPageModal() ? router.push(`/job/${store.jobs[0].id}`) : dispatch({ storeId: store.id, job: store.jobs[0].id })
    },
    [jobPageModal, router, dispatch]
  )

  const handleFilteredJobClose = useCallback(() => {
    dispatch({ job: undefined })
  }, [dispatch])

  const resetFilters = useCallback(() => {
    dispatch({ search: [], categories: [], payMin: null, jobCategories: [] })
  }, [dispatch])

  // #region Slug Page
  // Store the slug in the query provider so we can use it around the app
  useQuery({
    queryKey: ["slug", slug] as const,
    queryFn: ({ queryKey: [, slug] }) => slug,
  })

  // Create the new slugHeroDetails (null or hydrate our content) (experiment: slug_hero_detail_v1)
  const slugHeroDetails = useMemo(() => {
    if (slug && typeof slug === "object" && slug.heroDetails.title && slug.heroDetails.content) {
      return {
        heroTitle: slug.heroDetails.title ?? "",
        heroContent: slug.heroDetails.content ?? "",
        imageUrl: slug.heroDetails.imageUrl ?? "",
        imageAltText: slug.heroDetails.imageAltText ?? "",
        imageWidth: slug.heroDetails.imageWidth ?? "",
        imageHeight: slug.heroDetails.imageHeight ?? "",
      }
    }
    return null
  }, [slug])

  // For the contextual slug experiment, we are testing if the contextual filters impact conversion rates + does the new
  // filter design impact metrics. Previously, we disabled filters on nursing + healthcare slugs, so to keep it apples
  // to apples, we must keep the filters disabled on those slug pages. We disable exposures here to prevent exposures on
  // mobile + pushing an exposure on each render.
  const slugPagesWithoutFilters = slugPagesWithFiltersDisabled({ disableExposureLog: true })
  const filtersHidden = !!slug && slugPagesWithoutFilters.includes(slug.slug)
  const updatedTitle = (slug?.ui_title ?? slug?.title)?.replace("Jobs Near Me", "")

  return (
    <div
      className={cn(
        "grid grid-rows-[min-content_auto] grid-cols-1 w-full h-full overflow-hidden",
        filtersHidden
          ? "md:grid-cols-[0_450px_1fr]"
          : sidebarFilterVersion({ disableExposureLog: true }) === "v2"
            ? "md:grid-cols-[250px_450px_1fr]"
            : "md:grid-cols-[220px_450px_1fr]",
        // We intercept the job page over the list view. On mobile, we want to hide the map in that case so that the
        // list does not flash as the user presses the next and previous buttons.
        currentPathname.startsWith("/job/") && !closingAnimation && !mapVisible && "max-sm:hidden"
      )}
    >
      {!isMedium ? (
        <WhatWhereHeader
          initialState={initialState}
          initialLocation={prefilledLocation}
          isLoading={jobsLoading && jobs.length === 0}
          className={cn("grid col-span-full border-b")}
          onFilterReset={resetFilters}
          currentSearchValue={state.search}
        />
      ) : (
        <MobileWhatWhere
          initialState={initialState}
          initialLocation={prefilledLocation}
          currentSlug={slug}
          hideFilters={filtersHidden}
          headerTitle={updatedTitle}
          onFilterReset={resetFilters}
          currentSearchValue={state.search}
        />
      )}
      {mountMap && (
        <GoogleMapsApiProvider>
          <GoogleMapsMap
            stores={stores}
            selectedStore={selectedStore}
            initialState={state}
            onMapChange={handleMapChange}
            onStoreClick={handleMapPinClicked}
            onClusterClick={handleClusterClick}
            className={cn(mapVisible ? "max-sm:block" : "max-sm:hidden")}
          />
        </GoogleMapsApiProvider>
      )}
      {(filtersHidden || !isMedium) &&
        (sidebarFilterVersion() === "v2" ? (
          <SlugFilters
            enabledFilters={slug?.sidebarFilters}
            relatedSlugs={slug?.relatedSlugs}
            headerTitle={updatedTitle}
            className={cn("overflow-y-auto h-full pb-8 no-scrollbar z-30", filtersHidden && "hidden")}
            filterVersion={sidebarFilterVersion()}
            hideFilters={filtersHidden}
          />
        ) : (
          <Filters
            enabledFilters={slug?.sidebarFilters}
            className={cn("overflow-y-auto bg-white h-full pb-8 no-scrollbar z-30", filtersHidden && "hidden")}
            filterVersion={sidebarFilterVersion()}
          />
        ))}
      {sidebarMode === "jobs" ? (
        <JobsSidebar
          jobs={jobs}
          // Only pass in the store ID if it's in the URL
          selectedStoreId={state.storeId}
          selectedJobId={state.job}
          title={slug?.title ?? sidebarTitle}
          className={cn(mapVisible && "max-md:hidden")}
          slugHeroDetails={slugHeroDetails as SlugHeroDetailsProps | null}
          relatedSlugs={slug?.relatedSlugs}
          onJobClick={handleJobCardClicked}
          onJobClose={handleJobPageClose}
          onFilteredJobClose={handleFilteredJobClose}
          search={state.search}
          zoom={state.zoom}
          isLoadingStores={jobsLoading && stores.length === 0}
        />
      ) : (
        <StoresSidebar
          isLoadingStores={jobsLoading && stores.length === 0}
          stores={stores}
          selectedStoreId={state.storeId ?? selectedStore?.id}
          selectedJobId={state.job}
          onStoreClick={handleStoreCardClicked}
          slugHeroDetails={slugHeroDetails as SlugHeroDetailsProps | null}
          relatedSlugs={slug?.relatedSlugs}
          title={slug?.title ?? ""}
          className={cn(mapVisible && "max-md:hidden")}
          onJobClick={handleJobClick}
          onJobClose={handleJobPageClose}
          search={state.search}
          zoom={state.zoom}
        />
      )}
      <MapListToggle className={cn("md:hidden w-screen")} onToggle={() => dispatch({ storeId: undefined })} />
      {selectedStore && mapVisible && isMedium && (
        <MobileStoreCardPreview
          store={selectedStore}
          selectedJobId={state.job}
          selectedJob={state.job && selectedStore ? selectedStore.jobs.find((job) => job.id === state.job) : undefined}
          onJobClick={handleJobClick}
          onJobClose={handleJobPageClose}
        />
      )}
      <UrlErrorDisplay />
    </div>
  )
}
WorkmapsContainer.whyDidYouRender = true
