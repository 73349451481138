import { CategoryOption, JobCategory, ApiStoreSearchResult } from "@/types"

// // Sorted by frequency of use in the database:
//
// SELECT category, COUNT(*) AS category_count
// FROM (
//   SELECT jsonb_array_elements_text(search_params -> 'categories') AS category
//   FROM your_table
// ) AS subquery
// GROUP BY category
// ORDER BY category_count DESC;

export const categories: CategoryOption[] = [
  { value: "fast-food", label: "Fast Food", emoji: "🍔" },
  { value: "casual-dining", label: "Casual Dining", emoji: "🍴" },
  { value: "fast-casual", label: "Fast Casual", emoji: "🍽️" },
  { value: "department-store", label: "Department Store", emoji: "🏬" },
  { value: "grocery-stores", label: "Grocery Stores", emoji: "🛒" },
  { value: "beauty", label: "Beauty", emoji: "💄" },
  { value: "electronics", label: "Electronics", emoji: "🔌" },
  { value: "discount", label: "Discount", emoji: "💸" },
  { value: "convenience", label: "Convenience", emoji: "🏪" },
  { value: "pizzerias", label: "Pizzerias", emoji: "🍕" },
  { value: "pets", label: "Pets", emoji: "🐾" },
  { value: "sporting-goods", label: "Sporting Goods", emoji: "🏈" },
  { value: "coffee-shop", label: "Coffee Shop", emoji: "☕" },
  { value: "clothing", label: "Clothing", emoji: "👕" },
  { value: "diner", label: "Diner", emoji: "🍽️" },
  { value: "arts-&-crafts", label: "Arts & Crafts", emoji: "🖌️" },
  { value: "pharmacy", label: "Pharmacy", emoji: "💊" },
  { value: "gas", label: "Gas", emoji: "⛽" },
  { value: "warehouse", label: "Warehouse", emoji: "🏭" },
  { value: "customer-service", label: "Customer Service", emoji: "📞" },
  { value: "cleaning-&-janitorial", label: "Cleaning & Janitorial", emoji: "🧹" },
  { value: "shopping", label: "Shopping", emoji: "🛍️" },
  { value: "delivery", label: "Delivery", emoji: "📦" },
  { value: "caregiving", label: "Caregiving", emoji: "🧑‍⚕️" },
  { value: "security", label: "Security", emoji: "🔒" },
  { value: "automotive", label: "Automotive", emoji: "🚘" },
  { value: "hobbies", label: "Hobbies", emoji: "🎨" },
  { value: "banking", label: "Banking", emoji: "🏦" },
  { value: "merchandising-and-marketing", label: "Merchandising and Marketing", emoji: "📈" },
  { value: "furniture", label: "Furniture", emoji: "🛋️" },
  { value: "tutoring", label: "Tutoring", emoji: "📚" },
  { value: "home-improvement", label: "Home Improvement", emoji: "🛠️" },
  { value: "driving", label: "Driving", emoji: "🚗" },
  { value: "substitute-teacher", label: "Substitute Teacher", emoji: "👨‍🏫" },
  { value: "trucking", label: "Trucking", emoji: "🚚" },
  { value: "appliances", label: "Appliances", emoji: "🧺" },
  { value: "medical", label: "Medical", emoji: "🏥" },
  { value: "teacher", label: "Teacher", emoji: "👩‍🏫" },
  { value: "hvac", label: "HVAC", emoji: "❄️" },
  { value: "food", label: "Food", emoji: "🥗" },
  { value: "insurance", label: "Insurance", emoji: "📄" },
  { value: "hotel", label: "Hotel", emoji: "🏨" },
  { value: "cannabis", label: "Cannabis", emoji: "🍃" },
  { value: "hardware-store", label: "Hardware Store", emoji: "🔨" },
  { value: "military", label: "Military", emoji: "🪖" },
  { value: "sales", label: "Sales", emoji: "💰" },
  { value: "construction", label: "Construction", emoji: "🏗️" },
  { value: "miscellaneous", label: "Miscellaneous", emoji: "🔍" },
  { value: "music", label: "Music", emoji: "🎵" },
  { value: "waste-management", label: "Waste Management", emoji: "♻️" },
  { value: "office", label: "Office", emoji: "🏢" },
  { value: "electrical", label: "Electrical", emoji: "⚡" },
  { value: "plumbing", label: "Plumbing", emoji: "🔧" },
  { value: "machine-operator", label: "Machine Operator", emoji: "🛠️" },
  { value: "counseling-&-therapy", label: "Counseling & Therapy", emoji: "💬" },
  { value: "salon-&-beauty", label: "Salon & Beauty", emoji: "💇" },
  { value: "entertainment", label: "Entertainment", emoji: "🎭" },
  { value: "senior-living", label: "Senior Living", emoji: "👵" },
  { value: "roofing", label: "Roofing", emoji: "🏠" },
  { value: "utilities", label: "Utilities", emoji: "💡" },
  { value: "parking-attendant", label: "Parking Attendant", emoji: "🚗" },
  { value: "forestry", label: "Forestry", emoji: "🌲" },
  { value: "books", label: "Books", emoji: "📚" },
  { value: "aerospace", label: "Aerospace", emoji: "🚀" },
  { value: "gyms", label: "Gyms", emoji: "🏋️" },
  { value: "installation", label: "Installation", emoji: "🔧" },
  { value: "uncategorized", label: "Uncategorized", emoji: "❓" },
  { value: "shoes", label: "Shoes", emoji: "👟" },
  { value: "recycling", label: "Recycling", emoji: "♻️" },
  { value: "airline", label: "Airline", emoji: "✈️" },
  { value: "plant-operator", label: "Plant Operator", emoji: "🏭" },
  { value: "mechanic", label: "Mechanic", emoji: "🔧" },
  { value: "movie-theatre", label: "Movie Theatre", emoji: "🎬" },
]

enum Section {
  ShiftPreference = "Shift Preference",
  BenefitsOffered = "Benefits Offered",
  AgeRequirement = "Age Requirement",
  AdditionalRequirement = "Additional Requirement",

  // Nursing
  NursingWorkSetting = "Nurse Work Setting",
  NursingSpecialty = "Nurse Specialty",
  NursingExperienceLevel = "Nurse Experience Level",
  NursingShiftSchedule = "Nurse Shift Schedule",
  NursingDegreeRequirement = "Nurse Degree Requirement",

  // Cook
  CookPositionType = "Cook Position Type",
  CookWorkSetting = "Cook Work Setting",
  CookKitchenShift = "Cook Kitchen Shift",

  // Warehouse
  WarehousePositionType = "Warehouse Position Type",
  WarehouseDepartment = "Warehouse Department",
  WarehouseCertificationRequirement = "Warehouse Certification Requirement",

  // Sales
  SalesIndustry = "Sales Industry",
  SalesType = "Sales Type",
  SalesWorkEnvironment = "Sales Work Environment",
  SalesTravelRequirement = "Sales Travel Requirement",

  // Retail
  RetailRole = "Retail Role",
  RetailStoreType = "Retail Store Type",
  RetailBonus = "Retail Bonus",

  //Merchandiser
  MerchandiserRole = "Merchandiser Role",
  MerchandiserWorkEnvironment = "Merchandiser Work Environment",

  PhysicalRequirement = "Physical Requirement",
}

export const jobCategories: JobCategory[] = [
  // Shift Preference
  { value: "allowsFullTime", label: "Full-Time", section: Section.ShiftPreference },
  { value: "allowsPartTime", label: "Part-Time", section: Section.ShiftPreference },
  // { value: "allowsFourdayWorkWeek", label: "4 Day Work Week", section: Section.ShiftPreference },
  { value: "overnightShift", label: "Overnight Shifts", section: Section.ShiftPreference },
  { value: "requireWeekendShift", label: "Weekend Shifts", section: Section.ShiftPreference },

  // Benefits Offered
  { value: "benefitHealthInsurance", label: "Health Insurance", section: Section.BenefitsOffered },
  { value: "allowsRemote", label: "Remote", section: Section.BenefitsOffered },

  // Sales Benefits moved to benefits offered
  { value: "hasCarAllowance", label: "Car Allowance", section: Section.BenefitsOffered },
  { value: "hasExpenseAccount", label: "Expense Account", section: Section.BenefitsOffered },

  // Nursing Work Setting
  { value: "isHospital", label: "Hospital", section: Section.NursingWorkSetting },
  { value: "isNursingHome", label: "Nursing Home", section: Section.NursingWorkSetting },
  { value: "isClinic", label: "Clinic", section: Section.NursingWorkSetting },
  { value: "isDoctorsOffice", label: "Doctors Office", section: Section.NursingWorkSetting },
  { value: "isPrivatePractice", label: "Private Practice", section: Section.NursingWorkSetting },
  { value: "isGroupPractice", label: "Group Practice", section: Section.NursingWorkSetting },
  { value: "isSpecialtyPractice", label: "Specialty Practice", section: Section.NursingWorkSetting },
  { value: "isPublicHealthClinic", label: "Public Health Clinic", section: Section.NursingWorkSetting },
  { value: "isUrgentCare", label: "Urgent Care", section: Section.NursingWorkSetting },

  // Nursing Specialty
  { value: "isICU", label: "ICU/Critical Care", section: Section.NursingSpecialty },
  { value: "isER", label: "Emergency", section: Section.NursingSpecialty },
  { value: "isMedicalSurgical", label: "Medical/Surgical", section: Section.NursingSpecialty },
  { value: "isSpecialtyUnit", label: "Specialty Unit", section: Section.NursingSpecialty },

  // Nursing Experience Level
  { value: "isNewRNGraduate", label: "New RN Graduate (0-3 Years)", section: Section.NursingExperienceLevel },
  { value: "isStaffRN", label: "Staff RN (1-3 Years)", section: Section.NursingExperienceLevel },
  { value: "isSeniorRN", label: "Senior RN (3-5 Years)", section: Section.NursingExperienceLevel },
  { value: "isChargeRN", label: "Charge RN (5+ Years)", section: Section.NursingExperienceLevel },

  // Nursing Shift Schedule
  { value: "isEightHourShift", label: "8 Hour Shift", section: Section.NursingShiftSchedule },
  { value: "isTenHourShift", label: "10 Hour Shift", section: Section.NursingShiftSchedule },
  { value: "isTwelveHourShift", label: "12 Hour Shift", section: Section.NursingShiftSchedule },

  // Nursing Degree Requirement
  { value: "isRNLicenseRequired", label: "RN License", section: Section.NursingDegreeRequirement },
  { value: "isBSNDegreeRequired", label: "BSN Degree", section: Section.NursingDegreeRequirement },
  { value: "isBLSRequired", label: "BLS Required", section: Section.NursingDegreeRequirement },

  // Cook Position Type
  { value: "isLineCook", label: "Line Cook", section: Section.CookPositionType },
  { value: "isPrepCook", label: "Prep Cook", section: Section.CookPositionType },
  { value: "isLeadCook", label: "Lead Cook", section: Section.CookPositionType },

  // Cook Work Setting
  { value: "isFineDining", label: "Fine Dining", section: Section.CookWorkSetting },
  { value: "isCasualDining", label: "Casual Dining", section: Section.CookWorkSetting },
  { value: "isFastCasual", label: "Fast Casual", section: Section.CookWorkSetting },
  { value: "isQuickService", label: "Quick Service", section: Section.CookWorkSetting },
  { value: "isHealthcareSetting", label: "Healthcare Setting", section: Section.CookWorkSetting },
  { value: "isEducationSetting", label: "Education Setting", section: Section.CookWorkSetting },
  { value: "isCorporateSetting", label: "Corporate Setting", section: Section.CookWorkSetting },

  // Cook Kitchen Shift
  { value: "isBreakfastLunch", label: "Breakfast/Lunch", section: Section.CookKitchenShift },
  { value: "isDinnerService", label: "Dinner Service", section: Section.CookKitchenShift },

  // Warehouse Position Type
  { value: "isMaterialHandler", label: "Material Handler", section: Section.WarehousePositionType },
  { value: "isEquipmentOperator", label: "Equipment Operator", section: Section.WarehousePositionType },
  { value: "isLeadSupervisor", label: "Lead/Supervisor", section: Section.WarehousePositionType },
  { value: "isForkliftDriver", label: "Forklift Driver", section: Section.WarehousePositionType },

  // Warehouse Departments
  { value: "isPickerPacker", label: "Picker/Packer", section: Section.WarehouseDepartment },
  { value: "isLoaderUnloader", label: "Loader/Unloader", section: Section.WarehouseDepartment },
  { value: "isShippingReceiving", label: "Shipping/Receiving", section: Section.WarehouseDepartment },

  // Warehouse Certification Requirement
  { value: "requiresForklift", label: "Forklift", section: Section.WarehouseCertificationRequirement },
  { value: "requiresPalletJack", label: "Pallet Jack", section: Section.WarehouseCertificationRequirement },
  { value: "requiresOrderPicker", label: "Order Picker", section: Section.WarehouseCertificationRequirement },
  { value: "requiresReachTruck", label: "Reach Truck", section: Section.WarehouseCertificationRequirement },

  // Sales Industry
  { value: "isTechnologySales", label: "Technology Sales", section: Section.SalesIndustry },
  { value: "isHealthcareSales", label: "Healthcare Sales", section: Section.SalesIndustry },
  { value: "isIndustrialSales", label: "Industrial Sales", section: Section.SalesIndustry },
  { value: "isConsumerSales", label: "Consumer Sales", section: Section.SalesIndustry },

  // Sales Type
  { value: "isB2BSales", label: "B2B Sales", section: Section.SalesType },
  { value: "isEnterpriseSales", label: "Enterprise Sales", section: Section.SalesType },
  { value: "isSMBSales", label: "SMB Sales", section: Section.SalesType },

  // Sales Work Environment
  { value: "isFieldSales", label: "Field Sales", section: Section.SalesWorkEnvironment },
  { value: "isOfficeBased", label: "Office Based", section: Section.SalesWorkEnvironment },
  { value: "isRemoteHybrid", label: "Remote/Hybrid", section: Section.SalesWorkEnvironment },

  // Sales Travel Requirement
  { value: "isLocalTravel", label: "Local Travel", section: Section.SalesTravelRequirement },
  { value: "isNationalTravel", label: "National Travel", section: Section.SalesTravelRequirement },
  { value: "isMinimalTravel", label: "Minimal Travel", section: Section.SalesTravelRequirement },
  { value: "is25Travel", label: "25% Travel", section: Section.SalesTravelRequirement },
  { value: "is50Travel", label: "50% Travel", section: Section.SalesTravelRequirement },

  // Retail Role
  { value: "isSalesAssociate", label: "Sales Associate", section: Section.RetailRole },
  { value: "isCustomerService", label: "Customer Service", section: Section.RetailRole },
  { value: "isCashier", label: "Cashier", section: Section.RetailRole },
  { value: "isCallCenter", label: "Call Center", section: Section.RetailRole },
  { value: "isServiceDesk", label: "Service Desk", section: Section.RetailRole },
  { value: "isReturnsExchanges", label: "Returns/Exchanges", section: Section.RetailRole },

  // Retail Store Type
  { value: "isDepartmentStore", label: "Department Store", section: Section.RetailStoreType },
  { value: "isSpecialtyRetail", label: "Specialty Retail", section: Section.RetailStoreType },
  { value: "isBigBoxRetail", label: "Big Box Retail", section: Section.RetailStoreType },
  { value: "isBoutique", label: "Boutique", section: Section.RetailStoreType },
  { value: "isMallRetail", label: "Mall Retail", section: Section.RetailStoreType },

  // Retail Bonus
  { value: "hasSalesBonus", label: "Sales Bonus", section: Section.RetailBonus },
  { value: "hasPerformanceBonus", label: "Performance Bonus", section: Section.RetailBonus },

  // Merchandiser Position
  { value: "isFieldMerchandising", label: "Field Merchandising", section: Section.MerchandiserRole },
  { value: "isStoreService", label: "Store Service", section: Section.MerchandiserRole },
  { value: "isDisplayDesigner", label: "Display Designer", section: Section.MerchandiserRole },

  // Merchandiser Work Environment
  { value: "isMultipleStores", label: "Multiple Stores", section: Section.MerchandiserWorkEnvironment },
  { value: "isSingleStore", label: "Single Store", section: Section.MerchandiserWorkEnvironment },
  { value: "isTerritoryBased", label: "Territory Based", section: Section.MerchandiserWorkEnvironment },
  { value: "isDepartmentSpecific", label: "Department Specific", section: Section.MerchandiserWorkEnvironment },

  // Physical Requirement
  { value: "requires25Lifting", label: "25lb Lifting", section: Section.PhysicalRequirement },
  { value: "requires50Lifting", label: "50lb Lifting", section: Section.PhysicalRequirement },
  { value: "requiresOver50Lifting", label: "Over 50lb Lifting", section: Section.PhysicalRequirement },
  { value: "requiresStanding", label: "Standing", section: Section.PhysicalRequirement },
  { value: "requiresWalking", label: "Walking", section: Section.PhysicalRequirement },
  { value: "requiresBending", label: "Bending", section: Section.PhysicalRequirement },
  { value: "requiresReaching", label: "Reaching", section: Section.PhysicalRequirement },
  { value: "requiresRepetitiveMotion", label: "Repetitive Motion", section: Section.PhysicalRequirement },
  { value: "requiresClimbing", label: "Climbing", section: Section.PhysicalRequirement },

  // Age Requirement
  { value: "allowsMinor", label: "Hires Under 18", section: Section.AgeRequirement },
  { value: "require21Older", label: "21+ Years Old", section: Section.AgeRequirement },

  // Additional Requirement
  { value: "requireCommercialDriversLicense", label: "CDL Required", section: Section.AdditionalRequirement },
  { value: "requireDriversLicense", label: "Driver's License", section: Section.AdditionalRequirement },
  { value: "requireDiploma", label: "High School Diploma", section: Section.AdditionalRequirement },
  { value: "requireResume", label: "Resume Required", section: Section.AdditionalRequirement },
  { value: "unionMembership", label: "Union", section: Section.AdditionalRequirement },
]

export const jobCategoriesSections = [
  "Shift Preference",
  "Nurse Shift Schedule",
  "Cook Kitchen Shift",
  "Benefits Offered",
  "Age Requirement",

  "Nurse Specialty",
  "Nurse Experience Level",
  "Nurse Work Setting",
  "Nurse Degree Requirement",

  "Cook Position Type",
  "Cook Work Setting",

  "Warehouse Position Type",
  "Warehouse Department",
  "Warehouse Certification Requirement",

  "Sales Industry",
  "Sales Type",
  "Sales Work Environment",
  "Sales Travel Requirement",

  "Retail Role",
  "Retail Store Type",
  "Retail Bonus",

  "Merchandiser Role",
  "Merchandiser Work Environment",

  "Physical Requirement",
  "Additional Requirement",
] as const

export const jobCategoriesBySection = (section: (typeof jobCategoriesSections)[number]) => {
  const filteredCategories = jobCategories.filter((category) => category.section === section)

  if (!filteredCategories.length) {
    return null
  }

  return filteredCategories
}

const allDisplayableCategoryKeys = jobCategories.reduce((acc: string[], category) => {
  if (category.label && category.section) {
    acc.push(category.value)
  }

  return acc
}, [])

export const hasAnyDisplayableJobCategories = (jobCategories: string[]) =>
  jobCategories.length > 0 && jobCategories.some((category) => allDisplayableCategoryKeys.includes(category))

export const uniqueJobCategories = (store: ApiStoreSearchResult) => {
  const jobs = store.jobs
  return jobCategories.filter((jobCategory) => jobs.some((job) => job.job_categories?.includes(jobCategory.value)))
}
