"use client"

import { APIProvider, Map as GoogleMap, useMap as useGoogleMap, type MapEvent } from "@vis.gl/react-google-maps"
import { MarkerClusterer, GridAlgorithm } from "@googlemaps/markerclusterer"
import { useIsMedium } from "@/lib/frontend/util"
import { useDebugFeedColors } from "@/lib/frontend/hooks"
import { getDistanceBetweenTwoPoints } from "@/lib/util"
import { EnrichedStore, MapProps, ReactPropsOnlyChildren } from "@/types"
import { useEffect, useCallback, useRef } from "react"
import { useMapLayer, useStoreFeedLayer } from "@/lib/frontend/hooks/statsig"
import { useMap } from "../MapProvider"
import styles from "./GoogleMapsMap.module.css"
import { cn } from "@/lib/frontend/shadcn"
import { useAnalytics } from "@/lib/frontend/hooks/useAnalytics"
import { RefineYourLocationMarker } from "./RefineYourLocationMarker"
import { SelectedPinContent } from "./SelectedPinContent"
import { MapMarkerModal } from "./MapMarkerModal"
import { StoreMarker } from "./Marker"
import { MapLayerControl } from "@/components/map/MapLayerControl"
import { useMapPin } from "@/lib/frontend/hooks/useMapPin"
import { useQuery } from "@tanstack/react-query"

function addTermsLinkToMap(map: google.maps.Map) {
  const interval = setInterval(() => {
    const ccElem = map.getDiv()?.querySelector<HTMLAnchorElement>(".gm-style-cc a")
    if (!ccElem) return
    clearInterval(interval)

    const clone = ccElem.cloneNode(true) as HTMLAnchorElement
    clone.innerText = "Workmaps ToS"
    clone.href = "/terms"

    const { paddingLeft, paddingRight } = getComputedStyle(ccElem.parentElement!)
    const totalPadding = `calc(${paddingLeft} + ${paddingRight})`
    clone.style.paddingLeft = totalPadding
    ccElem.parentElement?.appendChild(clone)
  }, 100)
}

const mapOptions = {
  minZoom: 11,
  maxZoom: 17,
  mapId: "44b3568c76d9ce56",
  zoomControl: true,
  zoomControlOptions: {
    // https://stackoverflow.com/a/70378086
    position: 6,
  },
  mapTypeControl: false,
  scaleControl: false,
  streetViewControl: false,
  rotateControl: false,
  fullscreenControl: false,
} satisfies google.maps.MapOptions

export type GoogleMapsMapProps = MapProps<EnrichedStore> & {
  className?: string
}

export const GoogleMapsMap = function GoogleMapsMap({
  initialState,
  onMapChange,
  stores,
  selectedStore,
  onStoreClick,
  onClusterClick,
  className,
}: GoogleMapsMapProps) {
  const isMedium = useIsMedium()
  const debugFeedColors = useDebugFeedColors()
  const { setMap: setMapFunctions, mapVisible } = useMap()
  const gmMap = useGoogleMap()
  const { refineYourLocation, refineYourLocationModalOpened, mapPinModal, exactLocationOnly } = useStoreFeedLayer()
  const { googleMapsMapId, layerControlsEnabled } = useMapLayer()
  const storesWithoutExactLocation = stores.filter((store) => !store.exact_location).length
  const exactLocationOnlyToggle =
    storesWithoutExactLocation > 0 &&
    storesWithoutExactLocation < stores.length &&
    stores.length > 100 &&
    exactLocationOnly()
  const {
    clickedPinMarkerRef,
    clickedPinMarker,
    clickedPinId,
    currentJobIndex,
    currentJob,
    handleClose,
    handleNextClick,
    handlePreviousClick,
    getHighlight,
    handleMarkerClick,
    getPinVariation,
    experiments,
  } = useMapPin({ selectedStore, stores, onStoreClick })

  // These are needed for each render and we do not want to overexpose and slow down the site
  const { data: cachedMapLayerEvaluations } = useQuery({
    queryKey: ["statsig", "experiment", "cachedMapLayerEvaluations"],
    initialData: { mapId: mapOptions.mapId, layerControls: false },
    queryFn: () => ({ mapId: googleMapsMapId(), layerControls: layerControlsEnabled() }),
  })

  // #region Map setup
  useEffect(() => {
    if (!gmMap) return

    setMapFunctions({
      goTo: ({ lat, lng, zoom }) => {
        gmMap.setCenter({ lat, lng })
        if (zoom) gmMap.setZoom(zoom)
      },
      map: gmMap,
    })

    addTermsLinkToMap(gmMap)
    window.spMap = gmMap
  }, [gmMap, setMapFunctions])

  const handleMapChange = useCallback(
    (event: MapEvent) => {
      const center = event.map.getCenter()
      onMapChange?.({
        lat: center?.lat(),
        lng: center?.lng(),
        zoom: event.map.getZoom(),
        googleMapEventType: event.type,
      })
    },
    [onMapChange]
  )

  // #region Clustering!
  const clusteringEnabled = false
  // const currentZoom = gmMap?.getZoom?.() ?? 0
  // const clusteringEnabled = currentZoom <= 14 && stores.length > 100
  const clusters = useRef<Map<string, google.maps.marker.AdvancedMarkerElement>>(new Map())
  const clusterer = useRef<MarkerClusterer | null>(null)
  const clusterClassName = cn(
    styles["cluster-pin"]
    // isTinyLittlePins && [
    //   "border-[1px] border-solid border-[#1C711A]",
    //   "bg-[#E1FDB9]",
    //   "text-[#404251] font-semibold",
    //   "p-1 rounded-full",
    // ]
  )

  // Initialize MarkerClusterer
  useEffect(() => {
    if (!gmMap) return
    if (!clusterer.current) {
      clusterer.current = new MarkerClusterer({
        map: gmMap,
        algorithm: new GridAlgorithm({}),
        renderer: {
          render(cluster, _stats, map) {
            // console.log("debug.cluster.render", cluster, map)
            const { count, position } = cluster

            // adjust zIndex to be above other markers
            const zIndex: number = 1

            const content = document.createElement("div")
            content.appendChild(document.createElement("span")).textContent = `${count} jobs`
            content.className = clusterClassName

            const clusterOptions: google.maps.marker.AdvancedMarkerElementOptions = {
              map,
              position,
              zIndex,
              content,
            }
            return new google.maps.marker.AdvancedMarkerElement(clusterOptions)
          },
        },
        onClusterClick: (event, cluster, map) => {
          map.setCenter(cluster.position)
          map.setZoom(16)
          onClusterClick?.(event, cluster, map)
        },
      })
    }
  }, [gmMap, onClusterClick, clusterClassName])

  // Update markers
  const clustersHash = Array.from(clusters.current.keys()).join("")
  useEffect(() => {
    // console.log("debug.cluster.update", clusters.current.size)
    if (clusteringEnabled) {
      clusterer.current?.addMarkers(Array.from(clusters.current.values()))
    } else {
      clusterer.current?.clearMarkers()
    }
  }, [clustersHash, clusteringEnabled])

  const setMarkerRef = (marker: google.maps.marker.AdvancedMarkerElement | null, key: string) => {
    if (marker && clusters.current.get(key)) return marker
    if (!marker && !clusters.current.get(key)) return marker

    if (!marker) {
      clusters.current.delete(key)
    } else {
      clusters.current.set(key, marker)
    }

    return marker
  }

  // On each event that we send to Segment, attach the map's zoom level and how big the map is in miles.
  const analytics = useAnalytics()
  const trackMapSizeInEvents = useCallback(
    async (event: MapEvent) => {
      await analytics.deregister("Workmaps.MapSize")
      await analytics.register({
        name: "Workmaps.MapSize",
        load: async () => true,
        isLoaded: () => true,
        version: "1.0.0",
        type: "before",
        track: (ctx) => {
          const bounds = event.map.getBounds()
          const zoom = event.map.getZoom()

          if (zoom) {
            ctx.updateEvent("properties.map_zoom", event.map.getZoom())
          }

          if (bounds) {
            const ne = bounds.getNorthEast()
            const sw = bounds.getSouthWest()

            const distance = getDistanceBetweenTwoPoints(
              { lat: ne.lat(), lon: ne.lng() },
              { lat: sw.lat(), lon: sw.lng() },
              "miles"
            )

            ctx.updateEvent("properties.map_hypotenuse_length", distance)
          }
          return ctx
        },
      })
    },
    [analytics]
  )

  const highlightJobs = stores
    .filter((store) => store.jobs && store.jobs.length > 0)
    .map((store) => store.jobs[0])
    .slice(0, 4)

  return (
    <div
      className={cn(
        mapVisible && isMedium
          ? "min-h-full fixed top-0 left-0 right-0 h-[1vh] z-[9]"
          : "min-h-[250px] grid row-start-2 col-start-1 row-end-2 col-end-1 md:row-start-2 m md:row-end-2 md:col-start-3 md:col-end-3",
        "min-w-full min-h-full",
        className
      )}
    >
      <GoogleMap
        defaultCenter={{ lat: initialState.lat, lng: initialState.lng }}
        defaultZoom={initialState.zoom}
        className={cn(
          "w-full h-full",
          // adjusts the triangle pointer at the bottom of the InfoWindow
          "[&_.gm-style-iw-tc::after]:!top-0 [&_.gm-style-iw-tc::after]:opacity-0"
        )}
        mapId={cachedMapLayerEvaluations.mapId}
        minZoom={mapOptions.minZoom}
        maxZoom={mapOptions.maxZoom}
        zoomControlOptions={{
          position: isMedium ? 9 : 6,
        }}
        mapTypeControl={false}
        scaleControl={false}
        streetViewControl={false}
        rotateControl={false}
        fullscreenControl={false}
        onDragend={handleMapChange}
        onZoomChanged={handleMapChange}
        onClick={(event) => {
          // We don't want an info window to show up when people click non-job places on the map
          if (event.detail.placeId) {
            event.stop()
          }
        }}
        onIdle={trackMapSizeInEvents}
        isFractionalZoomEnabled={false}
      >
        {!isMedium && refineYourLocation() && (
          <RefineYourLocationMarker modalOpened={refineYourLocationModalOpened()} />
        )}

        {stores
          .filter((store) => {
            return store.exact_location || !exactLocationOnlyToggle
          })
          .map((store, i) => {
            const storeIsSelected = selectedStore?.id === store.id

            const highlightedJob = highlightJobs?.find((job) => job.store_id === store.id)
            const highlightJob = getHighlight(highlightedJob)

            return (
              <StoreMarker
                key={store.id}
                store={store}
                zIndex={10000 - i + (storeIsSelected ? 100000 : 0) + (highlightJobs ? 100000 : 0)}
                onClick={() => handleMarkerClick(store, highlightedJob)}
                highlight={highlightJob}
                perkHighlight={highlightJob}
                debugFeedColors={debugFeedColors}
                ref={(marker) => {
                  if (clusteringEnabled) {
                    setMarkerRef(marker, store.id)
                  }
                  if (clickedPinId === store.id && mapPinModal()) {
                    clickedPinMarkerRef(marker)
                  }
                }}
                mapPinModal={mapPinModal()}
                pinVariation={getPinVariation}
                pinHighlights={experiments.highlights}
                pinPerkHighlights={experiments.perkHighlights}
                selected={storeIsSelected}
              />
            )
          })}

        {selectedStore && clickedPinId && currentJob && mapPinModal() && (
          <MapMarkerModal
            anchor={clickedPinMarker}
            onClose={handleClose}
            headerDisabled
            showNextPreviousButtons={selectedStore?.jobs && selectedStore?.jobs.length > 1}
            disablePreviousButton={currentJobIndex === 0}
            disableNextButton={selectedStore?.jobs && currentJobIndex === selectedStore.jobs.length - 1}
            onNextClick={handleNextClick}
            onPreviousClick={handlePreviousClick}
            pixelOffset={[0, 20]}
            className="w-[360px]"
          >
            <SelectedPinContent store={selectedStore} job={currentJob} handleClose={handleClose} />
          </MapMarkerModal>
        )}
      </GoogleMap>
      {cachedMapLayerEvaluations.layerControls && <MapLayerControl />}
    </div>
  )
}

export const GoogleMapsApiProvider: React.FC<ReactPropsOnlyChildren> = ({ children }) => {
  return (
    <APIProvider
      apiKey={process.env.NEXT_PUBLIC_GOOGLE_MAPS_API_KEY}
      version="weekly"
      libraries={["core", "maps", "marker"]}
    >
      {children}
    </APIProvider>
  )
}
