import { Button } from "./ui/button"
import { Flex } from "./ui/flex"
import { Text } from "./ui/text"
import { RadioGroup, RadioGroupItem } from "./ui/radio-group"
import { Label } from "./ui/label"
// import { LabeledCheckbox } from "./ui/checkbox"
import { ChipButton } from "./ui/chip"
import { Collapsible, CollapsibleContent, CollapsibleTrigger } from "./ui/collapsible"
import { ReactNode, useState } from "react"
import { useWorkmapsContext } from "@/lib/frontend/hooks"
import { cn } from "@/lib/frontend/shadcn"
import { jobCategories } from "@/lib/shared/categories"
import { appliedFiltersCount, isFilter } from "@/lib/shared/filters"
import { useAnalytics } from "@/lib/frontend/hooks/useAnalytics"
import React from "react"
import { SidebarFilterType } from "@/types"
import { useStoreFeedLayer } from "@/lib/frontend/hooks/statsig"
import { AlertWidget } from "./AlertWidgetCapture"

type CollapsibleStates = {
  [key: string]: boolean
}

type FilterSectionProps = {
  section: string
  open: boolean
  onToggle: () => void
  children: ReactNode
  isFirstOnFilterList?: boolean
  className?: string
}

type FiltersProps = {
  className?: string
  onClose?: () => void
  enabledFilters?: SidebarFilterType[]
  filterVersion?: string
}

const FilterSection = ({ section, open, onToggle, children, isFirstOnFilterList, className }: FilterSectionProps) => (
  <Collapsible open={open} onOpenChange={onToggle} className="space-y-3">
    {!isFirstOnFilterList && <hr />}
    <Flex justify="between" align="center">
      <Text weight="semibold" size="sm">
        {section}
      </Text>
      <CollapsibleTrigger asChild>
        {/* <Button variant="transparent" size="sm" className={cn("p-0 text-[#4dabf7]")}>
          {open ? "Hide" : "Show"}
        </Button> */}
      </CollapsibleTrigger>
    </Flex>
    <CollapsibleContent className={cn("space-y-2", className)}>{children}</CollapsibleContent>
  </Collapsible>
)

// const feedList = ["appcast_cpa", "appcast_cpc", "recruitics", "career_wallet", "what_jobs"]
const minPayList = [15, 20, 25, 30, 40]

export const Filters = ({ className, onClose, enabledFilters, filterVersion }: FiltersProps) => {
  const { state, dispatch } = useWorkmapsContext()
  const analytics = useAnalytics()
  const [openSection, setOpenSection] = useState<CollapsibleStates>({
    Feeds: true,
    "Minimum Pay": true,
    "Benefits Offered": true,
    "Shift Preference": true,
    "Age Requirement": true,
    "Additional Requirement": true,
  })

  const { defaultSidebarFilters } = useStoreFeedLayer()

  const filters = enabledFilters?.length && filterVersion !== "v1" ? enabledFilters : defaultSidebarFilters()

  // show/hide each section
  const toggleSection = (section: string) => {
    setOpenSection((prevState) => {
      const newState = {
        ...prevState,
        [section]: !prevState[section],
      }

      // Track the event based on the new state
      if (newState[section]) {
        analytics.track(`${section} Shown`)
      } else {
        analytics.track(`${section} Hidden`)
      }

      return newState
    })
  }

  const JobCategoriesBySection = ({
    section,
    isFirstOnFilterList,
  }: {
    section: string
    isFirstOnFilterList: boolean
  }) => {
    // filter jobs categories by section
    const filteredJobCategoriesBySection = jobCategories.filter((jobCategory) => jobCategory.section === section)

    // check to see if all categories in section are selected
    const allCategoriesSelected = filteredJobCategoriesBySection.every((jobCategory) =>
      state.jobCategories.includes(jobCategory.value)
    )

    return (
      <>
        {/* if all is selected, hide the section */}
        {!allCategoriesSelected && (
          <FilterSection
            section={section !== "Benefits Offered" ? section + "s" : section}
            open={openSection[section]}
            onToggle={() => toggleSection(section)}
            className={cn("gap-1.5 flex flex-wrap space-y-0")}
            isFirstOnFilterList={isFirstOnFilterList}
          >
            {filteredJobCategoriesBySection.map(
              (jobCategory) =>
                // if job category is added, don't show the chip
                !state.jobCategories.includes(jobCategory.value) && (
                  <ChipButton add key={jobCategory.value} onClick={() => handleJobCategoryFilter(jobCategory.value)}>
                    {jobCategory.label}
                  </ChipButton>
                )
            )}
          </FilterSection>
        )}
      </>
    )
  }

  const handleMinPayFilter = (currentValue: string) => {
    dispatch({
      payMin: currentValue ? Number(currentValue) : null,
      callerLocation: "handleMinPayFilter",
    })
    analytics.track("Filter Interaction", {
      type: "minPay",
      positive: true,
      filterVersion,
      currentValue,
    })
  }

  const handleJobCategoryFilter = (currentValue: string) => {
    // only add job category if it's not already in list to prevent duplicates
    if (!state.jobCategories.includes(currentValue)) {
      dispatch({ jobCategories: [...state.jobCategories, currentValue], callerLocation: "handleJobCategoryFilter" })
      analytics.track("Filter Interaction", {
        type: "jobCategory",
        positive: true,
        filterVersion,
        currentValue,
        previousValue: state.jobCategories,
      })
    }
  }

  // const handleFeedFilter = (currentValue: string, isChecked: boolean) => {
  //   const checkedFeedFilters = isChecked
  //     ? [currentValue, ...(state.feeds ?? [])] // add currentValues to feeds
  //     : (state.feeds ?? []).filter((feed) => feed !== currentValue) // remove currentValue from feeds

  //   dispatch({ feeds: checkedFeedFilters })
  //   analytics.track("Feed Filter Applied", { feeds: currentValue })
  // }

  const handleRemoveFilter = (currentValue: string) => {
    if (currentValue === "minimumPay") {
      dispatch({ payMin: null })
      analytics.track("Filter Interaction", {
        type: "minPay",
        positive: false,
        filterVersion,
        currentValue,
      })
    } else {
      const jobCategories = state.jobCategories.filter((category) => category !== currentValue)
      const feeds = (state.feeds ?? []).filter((feed) => feed !== currentValue)

      dispatch({
        jobCategories: jobCategories,
        feeds: feeds,
        callerLocation: "handleRemoveFilter",
      })
      if (feeds) {
        analytics.track("Filter Interaction", {
          type: "feeds",
          positive: false,
          filterVersion,
          currentValue,
        })
      }
      if (jobCategories) {
        analytics.track("Filter Interaction", {
          type: "jobCategory",
          positive: false,
          filterVersion,
          currentValue,
        })
      }
    }
  }

  const handleRemoveAllFilters = () => {
    dispatch({ payMin: null, jobCategories: [], feeds: [], callerLocation: "handleRemoveAllFilters" })
    analytics.track("Filter Interaction", {
      type: "resetAll",
      positive: false,
      filterVersion,
    })
  }

  const handleCloseButtonClick = () => {
    if (onClose) {
      onClose()
    }
    analytics.track("Mobile Filters Close Button Clicked", { filterVersion: filterVersion })
  }

  const filterCount = appliedFiltersCount(state)
  const isFilterPresent = isFilter(state)

  return (
    <Flex direction="col" className={cn(className)}>
      <Flex direction="column" gap="xs" className={cn("sticky top-0 bg-white py-2.5 px-5", filterCount && "border-b")}>
        <Flex justify="between" align="center">
          {filterCount > 0 ? (
            <Text size="xs" weight="semibold" className="md:pb-1.5">
              {filterCount} Filter{filterCount > 1 ? "s" : ""} Applied
            </Text>
          ) : (
            <Text weight="semibold">Filters</Text>
          )}

          <Button
            variant="transparent"
            className={cn("p-0 font-medium text-muted-foreground md:hidden")}
            onClick={handleCloseButtonClick}
          >
            Close
          </Button>
        </Flex>

        <Flex className={cn("flex-wrap gap-1.5", !isFilterPresent && "hidden")}>
          {state.payMin && state.payMin > 0 && (
            <ChipButton remove onClick={() => handleRemoveFilter("minimumPay")}>
              ${state.payMin}+ per hour
            </ChipButton>
          )}
          {state.jobCategories.map((jobCategoryValue) => {
            // match the value to the label
            const jobCategory = jobCategories.find((category) => category.value === jobCategoryValue)

            return jobCategory ? (
              <ChipButton key={jobCategoryValue} remove onClick={() => handleRemoveFilter(jobCategoryValue)}>
                {jobCategory.label}
              </ChipButton>
            ) : null
          })}
          {/* {state.feeds?.map((feed) => {
            return (
              <Chip key={feed} remove buttonClick={() => handleRemoveFilter(feed)}>
                {feed.replace("_", " ").replace(/\b\w/g, (char) => char.toUpperCase())}
              </Chip>
            )
          })} */}
        </Flex>

        {isFilterPresent && (
          <Button
            variant="transparent"
            size="sm"
            className={cn("p-0 text-[#4dabf7] justify-start")}
            onClick={handleRemoveAllFilters}
          >
            Clear All
          </Button>
        )}

        <AlertWidget className={cn("mt-1 max-md:hidden")} />
      </Flex>

      <Flex direction="column" gap="md" className="px-5 pt-2.5">
        {filters.map((filter, index) => {
          if (filter === "Minimum Pay") {
            return (
              <FilterSection
                key={filter}
                section="Minimum Pay"
                open={openSection["Minimum Pay"]}
                onToggle={() => toggleSection("Minimum Pay")}
                isFirstOnFilterList={index === 0}
              >
                {/* can't set null for value so empty string helps clear the radio selection */}
                <RadioGroup value={state.payMin?.toString() ?? ""} onValueChange={handleMinPayFilter}>
                  {minPayList.map((value) => (
                    <div key={value} className={cn("flex items-center space-x-2")}>
                      <RadioGroupItem value={value.toString()} id={value.toString()} />
                      <Label htmlFor={value.toString()}>${value}+ per hour</Label>
                    </div>
                  ))}
                </RadioGroup>
              </FilterSection>
            )
          }

          return <JobCategoriesBySection key={filter} section={filter} isFirstOnFilterList={index === 0} />
        })}

        {/* <FilterSection section="Feeds" open={openSection["Feeds"]} onToggle={() => toggleSection("Feeds")}>
          {feedList.map((value) => (
            <div key={value} className={cn("flex items-center space-x-2")}>
              <LabeledCheckbox
                label={value.replace("_", " ").replace(/\b\w/g, (char) => char.toUpperCase())}
                id={value}
                value={value}
                checked={(state.feeds ?? []).includes(value)}
                onCheckedChange={(isChecked: boolean) => handleFeedFilter(value, isChecked)}
              />
            </div>
          ))}
        </FilterSection>

        <hr /> */}
      </Flex>
    </Flex>
  )
}
